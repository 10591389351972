import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalMensajeComponent } from 'src/app/components/general/modal-mensaje/modal-mensaje.component';
import { User } from 'src/app/models/user';
import { LoginService } from 'src/app/services/login.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MapaService } from 'src/app/services/mapa.service';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import { FollowService } from 'src/app/services/follow.service';
import { MetricaService } from 'src/app/services/metrica.service';

@Component({
  selector: 'app-registrarse',
  templateUrl: './registrarse.component.html',
  styleUrls: ['./registrarse.component.css']
})
export class RegistrarseComponent implements OnInit {

  user: User;
  repetirPassword: string;
  refModalMensaje: any;
  isMobile: boolean;
  provincias: any[];
  municipios: any[];
  filteredMunicipios: any[];
  selectedProvincia: number;
  selectedMunicipio: number;
  localidadSeleccionada: any;
  provinciaNombre: any;
  municipioNombre: any;
  marca: string = '';
  pantallaCarga: boolean = false;
  optionsSelect: Select2Options;
  nombreMarca: string = '';
  restauranteClickcado: string = '';
  platoClickcado: string = '';
  marcaId: string = '';
  marcaFicha = null;
  accion = null;
  inicializadoProvincia = false;
  validCif: boolean = false;

  @ViewChild('modalMensaje', { read: ViewContainerRef, static: false }) entry: ViewContainerRef;

  constructor(
    private lService: LoginService,
    private router: Router,
    private resolver: ComponentFactoryResolver,
    private deviceDetector: DeviceDetectorService,
    private mapService: MapaService,
    private fService: FollowService,
    private mService: MetricaService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    this.user = new User();
    this.isMobile = this.deviceDetector.isMobile();
    this.marca = localStorage.getItem('nombreMarca');

    this.mapService.getProvincias().subscribe((provincias) => {
      this.provincias = provincias;
      this.provincias.unshift({ id: -1, text: 'Provincia' });
    });
    this.mapService.getMunicipios().subscribe((municipios) => {
      this.municipios = municipios;
      //this.onProvinciaChange(1);
    });

    this.nombreMarca = localStorage.getItem('nombreMarca');
    this.restauranteClickcado = localStorage.getItem('restauranteClickcado');
    this.platoClickcado = localStorage.getItem('platoClickcado');
    this.marcaId = localStorage.getItem('marcaId');

    this.route.queryParamMap.subscribe((params) => {
      this.marcaFicha = params.get('marcaFicha');
      this.accion = params.get('accion');
    });
  }

  irIniciarSesion = () => {
    //this.router.navigateByUrl('login');
    this.router.navigate(['/login']);
  }

  registrarse = () => {

    if (this.validarFormRegistro()) {

      this.pantallaCarga = true;
      let model = {
        user: this.user,
        interacionMapaMarca: {
          nombreMarca: this.nombreMarca,
          restaurante: this.restauranteClickcado,
          articulo: this.platoClickcado,
          marcaId: this.marcaId
        }

      };

      this.lService.registrarse(model).subscribe(result => {

        if ((<any>result).result == "exist") {
          this.mostrarMensajeModal("Registrarse", "Ya existe un usuario con ese email. Puedes acceder a tu cuenta pinchando arriba en el botón de \"Iniciar sesión\"");
        } else if ((<any>result).result == "existNombreUsuario") {
          this.mostrarMensajeModal("Registrarse", "Ya existe un usuario con ese nombre de usuario. Prueba con otro nombre de usuario diferente.");
        } else if ((<any>result).result == "ok") {
          this.mostrarMensajeModalRegistroCorrecto("Ya casi estas registrado", "Revisa tu bandeja de entrada o \"no deseados\" y pincha en el enlace para activar tu cuenta.");
          this.irIniciarSesion;
        } else if ((<any>result).result == "error") {
          this.mostrarMensajeModal("Registrarse", "Se ha producido un error inesperado.");
        }
        this.pantallaCarga = false;
      });
    }
  }

  mostrarMensajeModalRegistroCorrecto(titulo, mensaje) {
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(ModalMensajeComponent);
    this.refModalMensaje = this.entry.createComponent(factory);
    this.refModalMensaje.instance.mensaje = mensaje;
    this.refModalMensaje.instance.titulo = titulo;
    this.refModalMensaje.instance.goCerrar = this.irIniciarSesion;
    this.refModalMensaje.changeDetectorRef.detectChanges();
  }


  validarFormRegistro() {

    let camposValidar = ["email", "politicas", "nombre_usuario"];
    let camposValidarPedido = ["nombre", "apellidos", "direccion", "cp", "telefono", "pais", "localidad"];
    let nombreCampos = {
      email: "Email",
      politicas: "Politicas",
      nombre_usuario: "Nombre Usuario",
      nombre: "Nombre",
      apellidos: "Apellidos",
      direccion: "Dirección",
      cp: "Código Postal",
      telefono: "Teléfono Móvil",
      pais: "Pais",
      localidad: "Municipio"
    };
    let mensaje = "Estos campos son obligatorios: ";
    let error = false;

    for (let i = 0; i < camposValidar.length; i++) {
      if (camposValidar[i] === "politicas") {
        if (!this.user.politicas) {
          mensaje = "Tienes que aceptar las politicas de privacidad y cookies";
          this.mostrarMensajeModal("Registrarse", mensaje);
          return false;
        }
      } else {
        if (this.user[camposValidar[i]] === undefined || this.user[camposValidar[i]] === "" || this.user[camposValidar[i]] === null) {
          mensaje += nombreCampos[camposValidar[i]] + ",";
          error = true;
        }
      }
    }

    /*if (this.accion == "pedido") {
      this.validarCifDni();
      if (this.validCif) {
        for (let i = 0; i < camposValidarPedido.length; i++) {
          if (this.user[camposValidarPedido[i]] === undefined || this.user[camposValidarPedido[i]] === "" || this.user[camposValidarPedido[i]] === null) {
            mensaje += nombreCampos[camposValidarPedido[i]] + ",";
            error = true;
          }
        }
      } else {
        mensaje = "Su DNI/CIF no es correcto.";
        this.mostrarMensajeModal("Registrarse", mensaje);
        return false;
      }

    }*/


    if (!this.verificarCorreo()) {
      mensaje = "No puedes registrarte con este correo porque esta mal escrito.";
      this.mostrarMensajeModal("Registrarse", mensaje);
      return false;
    }

    if (!this.verificarNombreUsuario()) {
      mensaje = "El nombre usuario tiene que tener las siguientes caracteristicas: <br><br> - Maximo 30 caracteres. <br> - Letras en minusculas, números y puede contener \"_\" y \".\".<br> - No puede contener espacios. <br>";
      this.mostrarMensajeModal("Registrarse", mensaje);
      return false;
    }

    if (!error) {
      return true;
    } else {
      mensaje = mensaje.substring(0, (mensaje.length - 1));
      this.mostrarMensajeModal("Registrarse", mensaje);
      return false;
    }
  }

  mostrarMensajeModal(titulo, mensaje) {
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(ModalMensajeComponent);
    this.refModalMensaje = this.entry.createComponent(factory);
    this.refModalMensaje.instance.mensaje = mensaje;
    this.refModalMensaje.instance.titulo = titulo;
    this.refModalMensaje.changeDetectorRef.detectChanges();
  }

  onProvinciaChange(value) {

    this.selectedProvincia = value;
    this.user.provincia = this.selectedProvincia;

    if (this.municipios != undefined) {
      this.filteredMunicipios = this.municipios.filter(
        (municipio) => +municipio.id_provincia === +this.selectedProvincia
      );
    }

  }
  onLocalidadChange(value) {
    this.localidadSeleccionada = value;
    this.user.localidad = this.localidadSeleccionada;
  }

  verificarCorreo() {

    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(this.user.email);

  }

  verificarNombreUsuario() {

    let nombreUsuarioRegex = /^[a-zA-Z0-9._]{1,30}$/;

    return nombreUsuarioRegex.test(this.user.nombre_usuario);

  }

  validarDNI(dni) {
    var numero
    var letr
    var letra
    var expresion_regular_dni

    expresion_regular_dni = /^\d{8}[a-zA-Z]$/;

    if (expresion_regular_dni.test(dni) == true) {
      numero = dni.substr(0, dni.length - 1);
      letr = dni.substr(dni.length - 1, 1);
      numero = numero % 23;
      letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
      letra = letra.substring(numero, numero + 1);
      if (letra != letr.toUpperCase()) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  isValidCif(abc) {
    if (abc != null) { //No haga nada si lo que se le pase por parametro este vacio
      let par = 0;
      let non = 0;
      let letras = "ABCDEFGHKLMNPQS";
      let letra = abc.charAt(0);

      if (abc.length != 9) {
        //alert('El Cif debe tener 9 dígitos');
        return false;
      }

      if (letras.indexOf(letra.toUpperCase()) == -1) {
        //alert("El comienzo del Cif no es válido");
        return false;
      }

      for (let zz = 2; zz < 8; zz += 2) {
        par = par + parseInt(abc.charAt(zz));
      }

      for (let zz = 1; zz < 9; zz += 2) {
        let nn = 2 * parseInt(abc.charAt(zz));
        if (nn > 9) nn = 1 + (nn - 10);
        non = non + nn;
      }

      let parcial = par + non;
      let control = (10 - (parcial % 10));
      if (control == 10) control = 0;

      if (control != abc.charAt(8)) {

        return false;
      }
      return true;
    }
    return false;
  }

  validarCifDni() {
    let valor = this.user.dni;

    if (valor != null && valor != "" && valor != undefined) {
      if (this.validarDNI(valor) || this.isValidCif(valor)) {
        this.validCif = true;
      } else {
        this.validCif = false;
      }
    } else {
      this.validCif = true;
    }

  }

  irLogin = () => {
    this.router.navigateByUrl('login');
  }

}
