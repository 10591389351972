import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { GLOBAL } from '../../../services/global';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  isMobile: boolean = false;
  user: any;
  inicializarNavegacion: boolean = true;

  @Output() vShowMap = new EventEmitter<string>();
  @Output() vShowPhotos = new EventEmitter<string>();
  @Output() vShowCard = new EventEmitter<string>();
  @Output() vShowFilter = new EventEmitter<string>();
  @Output() vShowSort = new EventEmitter<string>();

  @Input() filterActive: boolean = false;
  @Input() activeButton: string = '';
  @Input() activeButtonStyle: any = {};

  constructor(private deviceDetector: DeviceDetectorService) { }

  ngOnInit() {
    this.isMobile = this.deviceDetector.isMobile();
    this.user = JSON.parse(localStorage.getItem("user"));

    this.inicializarNavegacion = false;
    this.cargarNavegacion();
    this.inicializarNavegacion = true;

  }

  showGoPhoto(inicializar) {
    this.activeButton = 'photo';
    this.activeButtonStyle = { 'photo': '#E35100' }; // Cambia el color a tu elección
    this.inicializarNavegacion = true;
    this.actualizarNavegacion('photo', inicializar);

    this.vShowPhotos.emit();
  }

  showGoMap(inicializar) {
    this.activeButton = 'map';
    this.activeButtonStyle = { 'map': '#E35100' }; // Cambia el color a tu elección
    this.actualizarNavegacion('map', inicializar);

    this.vShowMap.emit();
  }

  showGoCard(inicializar) {

    this.activeButton = 'card';
    this.activeButtonStyle = { 'card': '#E35100' }; // Cambia el color a tu elección
    this.actualizarNavegacion('card', inicializar);

    this.vShowCard.emit();
  }

  showGoFilter(inicializar) {

    this.activeButton = 'filter';
    this.activeButtonStyle = { 'filter': '#E35100' }; // Cambia el color a tu elección
    this.actualizarNavegacion('filter', inicializar);

    this.vShowFilter.emit();
  }

  showGoSort() {
    this.vShowSort.emit();
  }

  cargarNavegacion() {
    let navegacion: any = localStorage.getItem("navegacion");
    if (navegacion != undefined) {
      navegacion = JSON.parse(navegacion);
      switch (navegacion.footer) {
        case "photo": {
          this.showGoPhoto(false);
          break;
        }
        case "map": {
          this.showGoMap(false);
          break;
        }
        case "card": {
          this.showGoCard(false);
          break;
        }
        case "filter": {
          this.showGoFilter(false);
          break;
        }
      }
    }
  }

  actualizarNavegacion(footer, inicializar) {
    let navegacion: any = localStorage.getItem("navegacion");
    if (navegacion != undefined) {
      navegacion = JSON.parse(navegacion);
      navegacion.footer = footer;
      if (inicializar) {
        navegacion.page = 1;
        navegacion.scroll = 0;
      }
      localStorage.setItem("navegacion", JSON.stringify(navegacion));
    }
  }

}
