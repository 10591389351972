import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { GLOBAL } from './global';
import { Plato } from '../models/plato';

@Injectable({
  providedIn: 'root'
})
export class ProductoService {

  constructor(
    private http: HttpClient
  ) { }

  // OBTENER INFO DE PLATO DESDE LARAVEL
  getInfoProducto(id): Observable<any> {
    const params = 'id=' + id;

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'producto/show', params, { headers });
  }

  getInfoProductoBySlug(slug): Observable<any> {
    const params = 'slug=' + slug;

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'producto/showBySlug', params, { headers });
  }

  // OBTENER LISTADO DE TAGS DESDE LARAVEL
  getTags(): Observable<any> {
    const params = '';
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'getTags', params, { headers });
  }

  // OBTENER LISTADO DE ALERGENOS DESDE LARAVEL
  getAlergenos(): Observable<any> {
    const params = '';
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'getAlergenos', params, { headers });
  }

  // SEND DICH INFO TO LARAVEL
  editInfoPlato(plato: Plato): Observable<any> {
    const json = JSON.stringify(plato);
    const params = 'dish=' + json;
    //console.log(params);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'editInfoPlato', params, { headers });
  }

  // NEW PLATO
  newPlato(plato: Plato): Observable<any> {
    const json = JSON.stringify(plato);
    const params = 'plato=' + json;
    //console.log(params);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'newPlato', params, { headers });
  }

  getPhotosProductos(searchBar, searchHastang, filtros, conFoto = true, page, paginaHasta = 0, pos = null): Observable<any> {
    const varSearchBar = JSON.stringify(searchBar);
    const varFiltros = JSON.stringify(filtros);
    let params = 'busqueda=' + varSearchBar + '&busquedaHastang=' + searchHastang + '&filtros=' + varFiltros + '&fotos=' + conFoto + '&pos=' + JSON.stringify(pos);
    if (page > 1) {
      params += '&page=' + page + "&paginaHasta=" + paginaHasta;
    }
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'producto/getFotosProductos', params, { headers });
  }

  getIdFabrProdHashtag(hashtagMarca): Observable<any> {
    const params = 'hashtag=' + hashtagMarca.label;
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'producto/getIdFabricanteByHash', params, { headers });
  }

}
