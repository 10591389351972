import { Component, OnInit,Input,ViewChild} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.css']
})
export class ModalConfirmComponent implements OnInit {

  isMobile:boolean=false;

  @Input() titulo: string;
  @Input() mensaje: string;
  @Input() goCerrar: Function;
  @Input() botones: { 
    texto: string, 
    accion: Function }[];

  @ViewChild("divTransparente",{static: false}) mensajeModal: { nativeElement: any; };
  @ViewChild("divMensaje",{static: false}) divMensaje: { nativeElement: any; };

  constructor(private deviceDetector:DeviceDetectorService) {
    
  }

  ngOnInit() {
    this.isMobile=this.deviceDetector.isMobile();
  }

  

  cerrar=()=>{

      $(this.mensajeModal.nativeElement).css("display","none");
    
    }

  funCerrar(){

      $(this.mensajeModal.nativeElement).css("display","none");
    
    }

  ngAfterViewInit(){
    if(this.isMobile){
      $(this.divMensaje.nativeElement).css("width","90%");
    }
  }

}
/* 
                          EJEMPLO DE IMPLEMENTACIÓN

  mostrarMensajeModal1(titulo,mensaje, textoBotonConfirmar,funcionConfirmar, textoBotonCancelar, funcionCancelar) {
  this.entry.clear();
  const factory = this.resolver.resolveComponentFactory(ModalConfirmComponent);
  this.refModalMensaje = this.entry.createComponent(factory);
  this.refModalMensaje.instance.mensaje = mensaje;
  this.refModalMensaje.instance.titulo = titulo;
  this.refModalMensaje.instance.botones = [
    { texto: textoBotonConfirmar, accion: funcionConfirmar },
    { texto: textoBotonCancelar, accion: funcionCancelar }

    //{ texto: textoBotonConfirmar, accion: () => console.log('Botón OK presionado') },
    //{ texto: textoBotonCancelar, accion: () => console.log('Botón Cancelar presionado') } 
  ];
  this.refModalMensaje.changeDetectorRef.detectChanges();
}

confir(){
  console.log("OKKKKKK");
};

cancelar(){
  console.log("Cancelarrrrrrrr");
}

prueba(){
  this.mostrarMensajeModal1("Texto titulo", "texto cuerpo", "Ok", this.confir ,"Cancelar", this.cancelar);
} */