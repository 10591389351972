export class Fabricante {
    id: number;
    nombre: string;
    hashtag: string;
    user_id: null;
    municipio: any;
    id_provincia: null;
    id_municipio: null;
    direccion: string;
    cp: string;
    telfijo: string;
    telmovil: string;
    mail: string;
    persona_contacto: string;
    cargo_contacto: string;
    movil_contacto: string;
    email_contacto: string;
    website: string;
    facebook: string;
    instagram: string;
    youtube: string;
    linkedin: string;
    twitter: string;
    lat: string;
    lng: string;
    fotoPerfil: string;
    fotoPortada: string;
    local: [];
    secciones: [];
    slug: string;
    take_away: boolean;
    provincia: any;
}
