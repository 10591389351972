import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PerfilService } from 'src/app/services/perfil.service';
import { GLOBAL } from 'src/app/services/global';
import { ModalMensajeComponent } from 'src/app/components/general/modal-mensaje/modal-mensaje.component';
import { ModalMensajeUrlComponent } from '../general/modal-mensaje-url/modal-mensaje-url.component';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-page',
  templateUrl: './create-page.component.html',
  styleUrls: ['./create-page.component.css']
})
export class CreatePageComponent implements OnInit {
  url: string;
  user: any;
  isMobile: boolean;
  refModalMensaje: any;
  validCif: boolean = false;

  @ViewChild('modalMensaje', { read: ViewContainerRef, static: false }) entry: ViewContainerRef;

  constructor(private route: ActivatedRoute,
    private deviceDetector: DeviceDetectorService,
    private pService: PerfilService,
    private router: Router,
    private resolver: ComponentFactoryResolver) { }

  ngOnInit() {
    this.isMobile = this.deviceDetector.isMobile();
    this.url = GLOBAL.url;
    this.route.queryParams.subscribe((params: any) => {
      this.user = JSON.parse(params.user);
    });
  }

  guardar() {
    if (this.validarForm()) {
      this.validarCifDni();
      if (this.validCif) {
        this.pService.createPage(this.user).subscribe(result => {
          if ((<any>result).result == "ok") {
            this.mostrarMensajeModalUrl("Crear Pagina", "Su pagina se ha creado correctamente. Para gestionar su pagina vaya a", "http://admin.uatifood.com", () => {
              this.atras();
            });
          } else if ((<any>result).result == "exist") {
            this.mostrarMensajeModal("Crear Pagina", "No se ha guardado los cambios porque el Nif o Cif ya existe.");
          } else {
            this.mostrarMensajeModal("Crear Pagina", "Se ha producido un error al editar el perfil.");
          }

        });
      }else{
        this.mostrarMensajeModal("Crear Pagina", "No se ha guardado los cambios porque el Nif o Cif introducido no es valido.");
      }
    }
  }

  validarForm() {
    let camposValidar = ["rolUser", "telefono", "razon_social", "nombre_comercial", "nif_cif", "ciudad"];
    let nombresEsteticos = {
      "rolUser": "¿Qué eres?",
      "telefono": "Teléfono",
      "razon_social": "Razón Social",
      "nombre_comercial": "Nombre Comercial",
      "nif_cif": "CIF",
      "ciudad": "Ciudad"
    };
    let mensaje = "Estos campos son obligatorios: ";
    let error = false;

    for (let i = 0; i < camposValidar.length; i++) {
      if (this.user[camposValidar[i]] == undefined || this.user[camposValidar[i]] == "" || this.user[camposValidar[i]] == null) {
        mensaje += nombresEsteticos[camposValidar[i]] + ",";
        error = true;
      }
    }

    if (!error) {
      return true;
    } else {
      mensaje = mensaje.substring(0, (mensaje.length - 1));
      this.mostrarMensajeModal("Crear Página", mensaje);
      return false;
    }
  }



  mostrarMensajeModal(titulo, mensaje) {
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(ModalMensajeComponent);
    this.refModalMensaje = this.entry.createComponent(factory);
    this.refModalMensaje.instance.mensaje = mensaje;
    this.refModalMensaje.instance.titulo = titulo;
    this.refModalMensaje.changeDetectorRef.detectChanges();
  }

  mostrarMensajeModalUrl(titulo, mensaje, mensajeUrl, funcionBoton) {
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(ModalMensajeUrlComponent);
    this.refModalMensaje = this.entry.createComponent(factory);
    this.refModalMensaje.instance.mensaje = mensaje;
    this.refModalMensaje.instance.titulo = titulo;
    this.refModalMensaje.instance.mensajeUrl = mensajeUrl;
    this.refModalMensaje.instance.boton = funcionBoton;
    this.refModalMensaje.changeDetectorRef.detectChanges();
  }

  atras = () => {
    this.router.navigate(['perfil'], { queryParams: { user_id: this.user.id } });
  }

  validarDNI(dni) {
    var numero
    var letr
    var letra
    var expresion_regular_dni

    expresion_regular_dni = /^\d{8}[a-zA-Z]$/;

    if (expresion_regular_dni.test(dni) == true) {
      numero = dni.substr(0, dni.length - 1);
      letr = dni.substr(dni.length - 1, 1);
      numero = numero % 23;
      letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
      letra = letra.substring(numero, numero + 1);
      if (letra != letr.toUpperCase()) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  isValidCif(abc) {
    if (abc != null) { //No haga nada si lo que se le pase por parametro este vacio
      let par = 0;
      let non = 0;
      let letras = "ABCDEFGHKLMNPQS";
      let letra = abc.charAt(0);

      if (abc.length != 9) {
        //alert('El Cif debe tener 9 dígitos');
        return false;
      }

      if (letras.indexOf(letra.toUpperCase()) == -1) {
        //alert("El comienzo del Cif no es válido");
        return false;
      }

      for (let zz = 2; zz < 8; zz += 2) {
        par = par + parseInt(abc.charAt(zz));
      }

      for (let zz = 1; zz < 9; zz += 2) {
        let nn = 2 * parseInt(abc.charAt(zz));
        if (nn > 9) nn = 1 + (nn - 10);
        non = non + nn;
      }

      let parcial = par + non;
      let control = (10 - (parcial % 10));
      if (control == 10) control = 0;

      if (control != abc.charAt(8)) {

        return false;
      }
      return true;
    }
    return false;
  }

  validarCifDni() {
    let valor = this.user.nif_cif;
    if (this.validarDNI(valor) || this.isValidCif(valor)) {
      this.validCif = true;
    } else {
      this.validCif = false;
    }
  }




}
