import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Reserva } from 'src/app/models/reserva';
import { ReservasService } from 'src/app/services/reservas.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { RestauranteService } from 'src/app/services/restaurante.service';
import { AgendaService, DayService, EventSettingsModel, MonthService, ScheduleComponent, View, WeekService, WorkWeekService } from '@syncfusion/ej2-angular-schedule';
import { DataManager, WebApiAdaptor } from '@syncfusion/ej2-data';
import { DataSource } from 'src/app/models/data-source';
import { v4 as uuidv4 } from 'uuid';
import { GLOBAL } from 'src/app/services/global';

@Component({
  selector: 'app-reservas',
  templateUrl: './reservas.component.html',
  styleUrls: ['./reservas.component.css'],
  providers: [DayService, WeekService, WorkWeekService, MonthService, AgendaService]
})
export class ReservasComponent implements OnInit {
  @ViewChild('scheduleObj', { static: true }) scheduleObj: ScheduleComponent;

  arrayBotones = [];
  horarios = [];
  reserva: Reserva;
  id: any;
  nombre: any;
  selected: any;
  email: any;
  tlf: any;
  hora: any;
  comensales: any;
  url = "";
  nombReserva: any;
  closeResult: any;
  restaurante: any;
  eventObject: EventSettingsModel;
  selectedDate: Date;
  currentView: View = 'Day';
  dataManager: DataManager;
  show = false;
  uuid: any;
  tempMesa: any;
  tempComensales: any;
  tempNombre: any;
  tempTelefono: any;
  temp: boolean;
  arrayMesas = [];
  ubicaciones = [];
  ubicacion: any;

  constructor(
    private route: ActivatedRoute,
    private reservaService: ReservasService,
    private modalService: NgbModal,
    private rService: RestauranteService,
  ) { }

  ngOnInit() {
    this.url = GLOBAL.url;
    this.route.params.subscribe(result => {
      this.id = result.id;
      this.nombre = result.nombre;
      this.arrayBotones = GLOBAL.arrayBotones(result.id);
      this.arrayMesas = GLOBAL.arrayMesas();
      this.ubicaciones = GLOBAL.arrayUbicaciones();
      
    });

    // OBTENER DATOS DEL RESTAURANTE
    this.rService.getInfoRestaurante(this.id).subscribe(result => {
      this.restaurante = result[0];

      // PREPARAMOS EL LIBRO DE RESERVAS
      // this.prepareSchedule();
      this.today();
      this.getReservas(this.id);
      this.tempMesa = '';
      this.tempComensales = 0;
      this.tempNombre = '';
      this.tempTelefono = '';
    });

    // TRAER ESTE ARAY DESDE LARAVEL SOLO CON LOS HORARIOS DISPONIBLES
    this.horarios = [
      {
        hora: '07:00 - 08:00'
      },
      {
        hora: '08:00 - 09:00'
      },
      {
        hora: '09:00 - 10:00'
      },
      {
        hora: '10:00 - 11:00'
      },
      {
        hora: '11:00 - 12:00'
      },
      {
        hora: '12:00 - 13:00'
      },
      {
        hora: '13:00 - 14:00'
      },
      {
        hora: '14:00 - 15:00'
      },
      {
        hora: '15:00 - 16:00'
      },
      {
        hora: '16:00 - 17:00'
      },
      {
        hora: '17:00 - 18:00'
      },
      {
        hora: '18:00 - 19:00'
      },
      {
        hora: '19:00 - 20:00'
      },
      {
        hora: '20:00 - 21:00'
      },
      {
        hora: '21:00 - 22:00'
      }
    ];

    // INICIALIZAR ESTO MEJOR POR SI DAMOS A RESERVAR SI HABER ELEGINO NIONGUNA OPCION
    this.hora = this.horarios[0].hora;
    this.comensales = '1';
  }

  // ESTABLECER FECHA DE HOY EN CALENDARIO
  today() {
    let date = new Date().toLocaleDateString();
    this.selectedDate = new Date(parseInt(date.split('/')[2]), parseInt(date.split('/')[1]) - 1, parseInt(date.split('/')[0]));
  }

  // SELECCIONAR FECHA
  change(event: any) {
    this.selected = event.endDate._d.toLocaleDateString();
    console.log(this.selected);
  }

  // SELECCIONAR COMENSALES
  selectComensales(number: any) {
    this.comensales = number;
  }

  // SELECCIONAR HORA
  selectHora(hora: any) {
    this.hora = hora;
  }

  // SELECCIONAR UBICACION 
  selectUbicacion(ubicacion: any) {
    this.ubicacion = ubicacion;
  }

  // REALIZAR RESERVA
  reservar(content: any) {
    this.tempMesa = '';
    this.tempComensales = 0;
    this.tempNombre = '';
    this.tempTelefono = '';

    this.reserva = new Reserva;
    this.reserva.fecha = this.selected;
    this.reserva.hora = this.hora;
    this.reserva.nMesa = "103";
    this.reserva.ubicacion = this.ubicacion;
    this.reserva.comensales = this.comensales;
    this.reserva.email = this.email;
    this.reserva.tlf = this.tlf;
    this.reserva.contacto = this.nombReserva;
    this.reserva.rest = this.restaurante.mail;
    this.reserva.id_rest = this.id;
    this.reserva.tempId = uuidv4();

    // MANDAR RESERVA A LARAVEL
    this.reservaService.nuevaReserva(this.reserva).subscribe(result => {
      console.log(result);
    });

    // AÑADIR DATOS DE LA RESERVA AL LIBRO DE RESERVAS
    this.addReservaToSchedule(this.selected, this.hora, this.comensales, this.nombReserva, this.tlf);
    this.scheduleObj.refresh();
    this.getReservas(this.id);

    // ABRIMOS MODAL CON MENSAJE DE CONFIRMACION
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  // ABRIR | CERRAR MODAL EDICIÓN INFO
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // AÑADIR RESERVA A ejs-schedule
  addReservaToSchedule(fecha: string, hora: string, comensales: string, nombre: string, telefono: string) {
    this.eventObject = {
      dataSource: [{
        Subject: nombre + ' - ' + comensales + ' - ' + telefono,
        Comensales: comensales,
        StartTime: new Date(parseInt(fecha.split('/')[2]), parseInt(fecha.split('/')[1]) - 1, parseInt(fecha.split('/')[0]), parseInt(hora.split(' - ')[0].split(':')[0]), parseInt(hora.split(' - ')[0].split(':')[1])),
        EndTime: new Date(parseInt(fecha.split('/')[2]), parseInt(fecha.split('/')[1]) - 1, parseInt(fecha.split('/')[0]), parseInt(hora.split(' - ')[1].split(':')[0]), parseInt(hora.split(' - ')[1].split(':')[1])),
        StartTimeZone: 'Europe/Madrid',
        EndTimeZone: 'Europe/Madrid'
      }]
    };
  }

  // OBTENER LISTADO DE RESERVAS DESDE LARAVEL
  getReservas(id: string) {
    this.reservaService.getReservas(id).subscribe(result => {
      let r = [];
      console.log(result);
      result.forEach(element => {
        let data: DataSource = new DataSource();
        data.Subject = element.contacto + ' - ' + element.comensales + ' - ' + element.telefono;
        data.StartTime = new Date(parseInt(element.fecha.split('/')[2]), parseInt(element.fecha.split('/')[1]) - 1, parseInt(element.fecha.split('/')[0]), parseInt(element.hora.split(' - ')[0].split(':')[0]), parseInt(element.hora.split(' - ')[0].split(':')[1]));
        data.EndTime = new Date(parseInt(element.fecha.split('/')[2]), parseInt(element.fecha.split('/')[1]) - 1, parseInt(element.fecha.split('/')[0]), parseInt(element.hora.split(' - ')[1].split(':')[0]), parseInt(element.hora.split(' - ')[1].split(':')[1]));
        data.TempId = element.tempId;
        data.NMesa = element.n_mesa;
        data.Ubicacion = element.ubicacion;
        r.push(data);
      });
      console.log(r);
      this.eventObject = {
        dataSource: r
      }
    });
  }

  // EVENTO CUANDO REALIZAMOS UNA ACCION EN LA VENTANA EMERGENTE DEL CALENDARIO
  onActionComplete(args): void {
    console.log('args', args);
    // EVENTO AL PULSAR BOTON SAVE
    if (args.requestType === "eventCreated") {
      let hStart = args.data[0].StartTime;
      let hEnd = args.data[0].EndTime;

      this.reserva = new Reserva;
      this.reserva.fecha = args.data[0].StartTime.toLocaleDateString();
      this.reserva.hora = hStart.getHours() + ':' + hStart.getMinutes() + ' - ' + hEnd.getHours() + ':' + hEnd.getMinutes();
      this.reserva.nMesa = args.data[0].NMesa;
      // this.reserva.ubicacion = args.data[0].Ubicacion;
      this.reserva.ubicacion = "comedor";
      this.reserva.comensales = args.data[0].Comensales;
      this.reserva.email = args.data[0].Email;
      this.reserva.tlf = args.data[0].Telefono;
      this.reserva.contacto = args.data[0].Nombre;
      this.reserva.rest = this.restaurante.mail;
      this.reserva.id_rest = this.id;
      this.reserva.tempId = uuidv4();

      console.log(this.reserva);
      // MANDAR RESERVA A LARAVEL
      this.reservaService.nuevaReserva(this.reserva).subscribe(result => {
        console.log(result);
      });
      this.scheduleObj.refresh();
      this.getReservas(this.id);
      this.tempMesa = '';
      this.tempComensales = 0;
      this.tempNombre = '';
      this.tempTelefono = '';
    }
    // EVENTO AL PULSAR EL BOTON BORRAR 
    else if (args.requestType === "eventRemoved") {
      this.reservaService.deleteReserva(args.data[0].TempId).subscribe(result => {
        console.log(result);
      });
      this.scheduleObj.refresh();
      this.getReservas(this.id);
      this.tempMesa = '';
      this.tempComensales = 0;
      this.tempNombre = '';
      this.tempTelefono = '';
    }
  }

  // EVENTO AL DESPLEGAR LA VENTANA DE DETALLE EN EL CALENDARIO
  onPopupOpen(event): void {
    console.log('popUp', event);
    if (event.type === "QuickInfo" && event.data.Subject) {
      this.tempMesa = event.data.NMesa;
      this.tempComensales = parseInt(event.data.Subject.split(' - ')[1]);
      this.tempNombre = event.data.Subject.split(' - ')[0];
      this.tempTelefono = event.data.Subject.split(' - ')[2];
      this.temp = false;
      this.ubicacion = event.data.Ubicacion;
    } else {
      this.tempMesa = '';
      this.tempComensales = null;
      this.tempNombre = '';
      this.tempTelefono = '';
      this.temp = true;
      this.ubicacion = '';
    }
  }
}
