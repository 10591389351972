export class Restaurante {
    capacidad: string;
    cargo_contacto: string;
    claim: string;
    cp: number;
    created_at: null;
    dieta: string;
    direccion: string;
    email_contacto: string;
    enlace_banner: null;
    enlace_carta: string;
    enlace_menu_del_dia: string;
    enlace_pizarra: string;
    enlace_portada: null;
    entretenimiento: string;
    espacio: string;
    facebook: string;
    horario_cena: string;
    horario_comida: string;
    horario_desayuno: string;
    horario_local: string;
    horario_merienda: string;
    id: number;
    ideal_para: [];
    instagram: string;
    lat: string;
    lng: string;
    mail: string;
    movil_contacto: string;
    municipio: string;
    nombre: string;
    origen_cocina: string;
    pago: string;
    persona_contacto: string;
    precio_medio_carta: string;
    reconocimiento: string;
    reservas: string;
    servicio_ininterrumpido: string;
    telefonofijo: string;
    telefonomovil: string;
    tipo_cocina: [];
    tipo_comidas: string;
    tipo_local: string;
    twitter: string;
    updated_at: null;
    user_id: null;
    valoraciones: string;
    website: string;
    youtube: string;
    qr: string;
    menu_del_dia: [];
    take_away: string;
    take_away_link: string;
    servicios: [];
    mas_servicios: [];
    dietas: [];
    local: [];
    espacios: string;
    secciones: [];
    slug: string;
}
