import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ModalMensajeComponent } from 'src/app/components/general/modal-mensaje/modal-mensaje.component';
import { LoginService } from 'src/app/services/login.service';
import { ModalConfirmComponent } from '../general/modal-confirm/modal-confirm.component';
import { FollowService } from 'src/app/services/follow.service';
import { LoadingComponent } from '../general/loading/loading.component';
import { ActivatedRoute } from '@angular/router';
import { MetricaService } from 'src/app/services/metrica.service';
//import { ActivatedRouteSnapshot } from '@angular/router';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user: User;
  refModalMensaje: any;
  isMobile: boolean = false;
  email: string = '';
  marca: string = '';
  slugPlato: string = '';
  slugMarca: string = '';
  nombreMarca: string = '';
  restauranteClickcado: string = '';
  platoClickcado: string = '';
  marcaId: string = '';

  show_for_marca: boolean = false;
  @ViewChild('modalMensaje', { read: ViewContainerRef, static: false }) entry: ViewContainerRef;


  pantallaCarga: boolean = false;

  @ViewChild('loadingMensaje', { read: ViewContainerRef, static: false }) entryLoading: ViewContainerRef;



  constructor(
    private lService: LoginService,
    private router: Router,
    private resolver: ComponentFactoryResolver,
    private deviceDetector: DeviceDetectorService,
    private fService: FollowService,
    private route: ActivatedRoute,
    private mService: MetricaService,
    //private route2: ActivatedRouteSnapshot

  ) { }

  ngOnInit() {
    this.user = new User();
    this.isMobile = this.deviceDetector.isMobile();

    this.marca = localStorage.getItem('slug');
    this.nombreMarca = localStorage.getItem('nombreMarca');
    this.restauranteClickcado = localStorage.getItem('restauranteClickcado');
    this.platoClickcado = localStorage.getItem('platoClickcado');
    this.marcaId = localStorage.getItem('marcaId');
    this.slugPlato = this.marca;

    this.route.paramMap.subscribe(params => {
      let marca = params.get('marca');
      if (marca != undefined && marca != '') {
        localStorage.setItem('slug', marca);
        this.slugPlato = marca;
        this.show_for_marca = true;
        this.marca = marca;
      }
    });

    this.route.queryParamMap.subscribe((params) => {

      if (params.get('nombreMarca') != null && params.get('nombreMarca') != "") {

        this.nombreMarca = params.get('nombreMarca');
        localStorage.setItem('nombreMarca', this.nombreMarca);

        if (params.get('restauranteClickcado') != null && params.get('restauranteClickcado') != "") {
          this.restauranteClickcado = params.get('restauranteClickcado');
          localStorage.setItem('restauranteClickcado', this.restauranteClickcado);
        }

        if (params.get('platoClickcado') != null && params.get('platoClickcado') != "") {
          if (params.get('platoClickcado') != "undefined" && params.get('platoClickcado') != undefined) {
            this.platoClickcado = params.get('platoClickcado');
            localStorage.setItem('platoClickcado', this.platoClickcado);
          }
        }

        if (params.get('marcaId') != null && params.get('marcaId') != "") {
          this.marcaId = params.get('marcaId');
          localStorage.setItem('marcaId', this.marcaId);
        }

        if (params.get('token') == undefined) {
          this.addMetrica(2)
        }
      }

      if (params.get('token') != undefined) {
        this.loginAuto(params.get('token'));
      }


    });

  }

  addMetrica(interaccionId) {

    let modeloMetrica = {
      mapa_marca_id: this.marcaId,
      tipo_interaccion: interaccionId,
    }

    if (this.restauranteClickcado != '' && this.restauranteClickcado != undefined) {
      modeloMetrica["establecimiento_id"] = this.restauranteClickcado;
    }

    if (this.platoClickcado != '' && this.platoClickcado != undefined) {
      modeloMetrica["articulo_id"] = this.platoClickcado;
    }
    this.mService.addMetrica(modeloMetrica).subscribe(result => {
      if (result.result == "ok") {
        if (interaccionId == 2) {
          console.log("Click guardada en metrica");
        } else {
          console.log("Destino Ok guardada en metrica");
        }

      } else {
        console.log("Error a la hora de guardar en metrica");
      }
    });
  }

  loginAuto(token) {
    this.lService.loginAuto(token).subscribe(result => {

      if ((<any>result).result == "ok") {
        localStorage.setItem("user", JSON.stringify((<any>result).user));
        this.navegarLogin(result);
      } else {
        this.mostrarMensajeModal("Iniciar sesión", "No se ha podido iniciar sesión");
      }
    });
  }

  navegarLogin(result) {

    if (this.nombreMarca != '' && this.nombreMarca != null && this.nombreMarca != undefined) {

      let id = (<any>result).user.id;
      this.fService.insertarFollowenLogin((<any>result).user.id, this.nombreMarca, this.slugPlato).subscribe(result => {

        let aux: any = result;
        let direccion = aux.slug;

        if ((<any>result).result == "ok") {

          localStorage.setItem('nombreMarca', '');
          localStorage.setItem('slug', '');

          this.addMetrica(4);

          localStorage.setItem('restauranteClickcado', '');
          localStorage.setItem('platoClickcado', '');
          localStorage.setItem('marcaId', '');

          if (this.platoClickcado != '' && this.platoClickcado != undefined) {
            this.router.navigateByUrl('plato/' + direccion + '?addFavorite=' + id);
          } else {
            this.router.navigateByUrl('establecimiento/' + this.slugPlato + '?addFavorite=' + id);
          }
        }

      });
    } else {

      localStorage.setItem('nombreMarca', '');
      localStorage.setItem('slug', '');
      localStorage.setItem('restauranteClickcado', '');
      localStorage.setItem('platoClickcado', '');
      localStorage.setItem('marcaId', '');

      if (this.router.navigateByUrl(this.route.snapshot.queryParams['returnUrl'])) {
        this.router.navigateByUrl(this.route.snapshot.queryParams['returnUrl']);
      } else {

        this.router.navigateByUrl('buscador');
      }
    }

  }

  login() {
    this.pantallaCarga = true;
    if (this.validarFormLogin()) {
      this.lService.login(this.user).subscribe(result => {
        if ((<any>result).result == "noExist") {
          this.pantallaCarga = false;
          this.mostrarMensajeModal("Login", "El email introducido es erroneo");
        } else if ((<any>result).result == "errorPassword") {
          this.pantallaCarga = false;
          this.mostrarMensajeModal("Login", "La contraseña es erronea");
        } else if ((<any>result).result == "noConfirmEmail") {
          this.pantallaCarga = false;
          this.mostrarMensajeModalPersonalizado("Login", "No has confirmado el correo electrónico. Para enviar de nuevo pulse en reenviar, gracias.", "Reenviar", this.reenviarEmail);
        } else if ((<any>result).result == "ok") {
          this.pantallaCarga = false;
          localStorage.setItem("user", JSON.stringify((<any>result).user));
          this.navegarLogin((<any>result));
        }
      });
    }

  }
  reenviarEmail = () => {
    //console.log("Email: " + this.user.email);
    this.lService.reenviarEmail(this.user.email).subscribe(result => {
      //console.log(result);
    });
    this.mostrarMensajeModal("Login", "Mensaje reenviado con éxito, por favor revise su bandeja de entrada.");
  }

  validarFormLogin() {
    let camposValidar = ["email", "password"];
    let nombreCampos = { email: "Email", password: "Contraseña" };
    let mensaje = "Estos campos son obligatorios: ";
    let error = false;

    for (let i = 0; i < camposValidar.length; i++) {
      if (this.user[camposValidar[i]] == undefined || this.user[camposValidar[i]] == "" || this.user[camposValidar[i]] == null) {
        mensaje += nombreCampos[camposValidar[i]] + ",";
        error = true;
      }
    }

    if (!error) {
      return true;
    } else {
      mensaje = mensaje.substring(0, (mensaje.length - 1));
      this.mostrarMensajeModal("Login", mensaje);
      return false;
    }
  }

  irRegistrate = () => {
    this.router.navigateByUrl('registrarse');
  }

  mostrarMensajeModal(titulo, mensaje) {
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(ModalMensajeComponent);
    this.refModalMensaje = this.entry.createComponent(factory);
    this.refModalMensaje.instance.mensaje = mensaje;
    this.refModalMensaje.instance.titulo = titulo;
    this.refModalMensaje.changeDetectorRef.detectChanges();
  }

  mostrarMensajeModalPersonalizado(titulo, mensaje, textoBotonConfirmar, funcionConfirmar) {
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(ModalConfirmComponent);
    this.refModalMensaje = this.entry.createComponent(factory);
    this.refModalMensaje.instance.mensaje = mensaje;
    this.refModalMensaje.instance.titulo = titulo;
    this.refModalMensaje.instance.botones = [
      { texto: textoBotonConfirmar, accion: funcionConfirmar },
      //{ texto: textoBotonCancelar, accion: funcionCancelar }

      /*      { texto: textoBotonConfirmar, accion: () => console.log('Botón OK presionado') },
           { texto: textoBotonCancelar, accion: () => console.log('Botón Cancelar presionado') } */
    ];
    this.refModalMensaje.changeDetectorRef.detectChanges();
  }

  mostrarLoading(mensaje) {
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(LoadingComponent);
    this.refModalMensaje = this.entry.createComponent(factory);
    this.refModalMensaje.instance.mensaje = mensaje;
    this.refModalMensaje.changeDetectorRef.detectChanges();
  }

}
