import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Restaurante } from '../models/restaurante';
import { Seccion } from '../models/seccion';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class FabricanteService {

  headers = {
    "Accept": "application/json",
    "Content-Type": "application/json"
  };

  constructor(
    private http: HttpClient
  ) { }

  // OBTENER INFORMACION DEL FABRICANTE
  getInfoFabricante(id: any): Observable<any> {
    const params = 'id=' + id;
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
  
    return this.http.post(GLOBAL.urlApi+'fabricante/show', params, { headers });
  }

  getInfoFabricanteBySlug(slug: any): Observable<any> {
    const params = 'slug=' + slug;
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
  
    return this.http.post(GLOBAL.urlApi+'fabricante/showBySlug', params, { headers });
  }
}
