export const GLOBAL = {
    id: '',

    //url:'http://uati.atech/', /* LOCAL */
    //url: 'https://desarrollo.admin.uatifood.com/', /* DESARROLLO */
    url: 'https://admin.photofood.app/', /* PRODUCCION */

    //urlApi: 'http://uati.atech/api/', /* LOCAL */
    //urlApi: 'https://desarrollo.admin.uatifood.com/api/', /* DESARROLLO */
    urlApi: 'https://admin.photofood.app/api/', /* PRODUCCION */

    //urlAdmin:'http://uati.atech/admin/',/* LOCAL */
    //urlAdmin: 'https://desarrollo.admin.uatifood.com/admin/', /* DESARROLLO */
    urlAdmin: 'https://admin.photofood.app/admin/', /* PRODUCCION */

    //urlApiValidate: 'http://uati.atech/api/user/', /* LOCAL */
    //urlResourcesCarta: 'http://uati.atech/images-restaurante/', /* LOCAL */
    //urlIcons: 'http://uati.atech/images-restaurante/icons/', /* LOCAL */

    //urlApiValidate: 'https://desarrollo.admin.uatifood.com/api/user/', /* DESARROLLO */
    //urlResourcesCarta: 'https://desarrollo.admin.uatifood.com/images-restaurante/', /* DESARROLLO */
    //urlIcons: 'https://desarrollo.admin.uatifood.com/images-restaurante/icons/', /* DESARROLLO */

    urlApiValidate: 'https://admin.photofood.app/api/user/', /* PRODUCCION */
    urlResourcesCarta: 'https://admin.photofood.app/images-restaurante/', /* PRODUCCION */
    urlIcons: 'https://admin.photofood.app/images-restaurante/icons/', /* PRODUCCION */

    urlQRCarta: 'https://localhost:4200/restaurante/',
    arrayBotones(id) {
        let arrayBotones = [
            {
                label: 'Carta',
                href: '/establecimiento/' + id
            },
            {
                label: 'Información',
                href: '/establecimiento/' + id + '/info'
            },
            /*{
                label: 'Reservas',
                href: '/restaurante/' + id + '/reserva'
            }*/
        ];
        return arrayBotones;
    },
    arrayBotonesQR(id) {
        let arrayBotones = [
            {
                label: 'Carta',
                href: '/restauranteQR/' + id
            },
            {
                label: 'Información',
                href: '/restauranteQR/' + id + '/info'
            },
            /*{
                label: 'Reservas',
                href: '/restaurante/' + id + '/reserva'
            }*/
        ];
        return arrayBotones;
    },
    arrayBotonesFabri(id) {
        let arrayBotones = [
            {
                label: 'Productos',
                href: '/fabricante/' + id
            },
            {
                label: 'Información',
                href: '/fabricante/' + id + '/info'
            },
            /*{
                label: 'Reservas',
                href: '/restaurante/' + id + '/reserva'
            }*/
        ];
        return arrayBotones;
    },
    arrayBotonesUser(id) {
        let arrayBotones = [
            {
                label: 'Usuario',
                href: '/usuario/' + id
            },
            /* {
                label: 'Restaurante',
                href: '/restaurante/' + id_res
            }, */
        ];
        return arrayBotones;
    },
    arrayUbicaciones() {
        let arrayUbicaciones = [
            {
                label: 'Comedor'
            },
            {
                label: 'Terraza'
            },
            {
                label: 'Barra'
            }
        ];
        return arrayUbicaciones;
    },
    arrayMesas() {
        let arrayMesas = [
            {
                ubicacion: 'comedor',
                cantidad: 10,
                max: 4,
                min: 2
            },
            {
                ubicacion: 'comedor',
                cantidad: 2,
                max: 2,
                min: 1
            },
            {
                ubicacion: 'terraza',
                cantidad: 6,
                max: 4,
                min: 2
            },
            {
                ubicacion: 'terraza',
                cantidad: 2,
                max: 2,
                min: 1
            }
        ];
        return arrayMesas;
    },
    filtrosGlobales: [] = "",
}