import { Component, OnInit } from '@angular/core';
import { Options } from 'ng5-slider';

@Component({
  selector: 'app-slider-range',
  templateUrl: './slider-range.component.html',
  styleUrls: ['./slider-range.component.css']
})
export class SliderRangeComponent implements OnInit {
  minValue: number = 50;
  maxValue: number = 200;

  options: Options = {
    floor: 0,
    ceil: 100
  };

  constructor() { }

  ngOnInit() {
  }

}
