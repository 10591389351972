import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  constructor(
    private http: HttpClient,
  ) { }

  getPhotosUsuarios(searchBar, searchHastang, filtros, conFoto = true, page): Observable<any> {

    const varFiltros = JSON.stringify(filtros);

    let params = 'busqueda=' + searchBar + '&busquedaHastang=' + searchHastang + '&filtros=' + varFiltros + '&fotos=' + conFoto;
    if (page > 1) {
      params += '&page=' + page;
    }
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'publicacion/getFotosUsuarios', params, { headers });
  }

  // OBTENER INFORMACION DE USUARIO POR SLUG
  getInfoUserBySlug(slug: any): Observable<any> {
    const params = 'slug=' + slug;
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'user/showBySlug', params, { headers });
  }

  actualizarNuevaConexion(usuarioId): Observable<any> {

    const params = 'usuarioId=' + usuarioId;
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'user/ultimaConexion', params, { headers });
  }

}
