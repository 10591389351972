import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ActivatedRoute, Router } from '@angular/router';
import { PerfilService } from 'src/app/services/perfil.service';
import { GLOBAL } from 'src/app/services/global';
import { ModalMensajeComponent } from 'src/app/components/general/modal-mensaje/modal-mensaje.component';
import { ModalConfirmComponent } from '../general/modal-confirm/modal-confirm.component';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {

  isMobile: boolean = false;
  user: any;
  seguidores: number;
  siguiendo: number;
  numPublicaciones: number;
  publicaciones: any;
  userLogin: any;
  url: string;
  refModalMensaje: any;

  visibilidadPublicaciones: boolean = false;
  visibilidadFavoritos: boolean = false;
  favoritos: any;

  nProductosFav: number[] = [];
  nPlatosFav: number[] = [];
  nRestaurantesFav: number[] = [];
  nUsuariosFav: number[] = [];
  favItems: any = null;
  tipoFav: any;
  opcionFavs: string;

  stars: { value: number, filled: boolean }[] = [];
  rolUsuario: number[] = [];

  @ViewChild('modalMensaje', { read: ViewContainerRef, static: false }) entry: ViewContainerRef;

  constructor(private deviceDetector: DeviceDetectorService,
    private route: ActivatedRoute,
    private router: Router,
    private pService: PerfilService,
    private resolver: ComponentFactoryResolver) { }

  ngOnInit() {
    this.isMobile = this.deviceDetector.isMobile();

    this.userLogin = JSON.parse(localStorage.getItem("user"));

    this.getPerfil(this.userLogin.id);
    this.getNumSeguidores(this.userLogin.id);
    this.getNumSiguiendo(this.userLogin.id);
    this.getNumPublicaciones(this.userLogin.id);
    this.getPublicaciones(this.userLogin.id);
    this.getFavoritos(this.userLogin.id);
    this.getRolUser(this.userLogin.id);

    this.stars = [
      { value: 1, filled: false },
      { value: 2, filled: false },
      { value: 3, filled: false },
      { value: 4, filled: false },
      { value: 5, filled: false }
    ];

    this.url = GLOBAL.url;

    this.toggleVisibilidadPublicaciones();
  }

  getPerfil(userId: number) {
    this.pService.getUserPerfil(userId).subscribe(result => {
      if (result.result == "ok") {
        this.user = result.user;
      } else {
        this.mostrarMensajeModal("Perfil", "Se ha producido un error al obtener los datos del perfil");
      }
    });
  }

  getRolUser(userId: number) {
    this.pService.getRolUser(userId).subscribe(result => {
      if (result.result == 'ok') {
        this.rolUsuario = result.rolUser;
        // console.log(this.rolUsuario);
      } else {
        this.mostrarMensajeModal("Rol", "Se ha producido un error al obtener el rol del usuario");
      }
    })
  }

  getNumSeguidores(userId: number) {
    this.pService.getNumUserSeguidores(userId).subscribe(result => {
      if (result.result == 'ok') {
        this.seguidores = result.numSeguidores;
      } else {
        this.mostrarMensajeModal("Seguidores", "Se ha producido un error al obtener el número de seguidores");
      }
    })
  }

  getNumSiguiendo(userId: number) {
    this.pService.getNumUserSiguiendo(userId).subscribe(result => {
      if (result.result == 'ok') {
        this.siguiendo = result.numSiguiendo;
      } else {
        this.mostrarMensajeModal("Seguidos", "Se ha producido un error al obtener el número de seguidos");
      }
    })
  }

  getNumPublicaciones(userId: number) {
    this.pService.getNumPublicaciones(userId).subscribe(result => {
      if (result.result == 'ok') {
        this.numPublicaciones = result.numPublicaciones;
      } else {
        this.mostrarMensajeModal("Publicaciones", "Se ha producido un error al obtener el número de publicaciones");
      }
    })
  }

  getPublicaciones(userId: number) {
    this.pService.getPublicaciones(userId).subscribe(result => {
      if (result.result == 'ok') {
        this.publicaciones = result.totalPublicaciones;
        for (let publicacion of this.publicaciones) {
          let fecha = new Date(publicacion.created_at);
          publicacion.fechaFormateada = fecha.getDay() + "/" + (fecha.getMonth() + 1) + "/" + fecha.getFullYear();
        }
        console.log(this.publicaciones);
      } else {
        this.mostrarMensajeModal("Publicaciones", "Se ha producido un error al obtener el listado de publicaciones");
      }
    })
  }

  getFavoritos(userId: number) {
    this.pService.getFavoritos(userId).subscribe(result => {
      if (result.result == 'ok') {
        this.favoritos = result.totalFavoritos;
        for (let i = 0; i < this.favoritos.length; i++) {
          this.nProductosFav.push(this.favoritos[i].producto_seg);
          this.nPlatosFav.push(this.favoritos[i].plato_seg);
          this.nRestaurantesFav.push(this.favoritos[i].restaurante_seg);
          this.nUsuariosFav.push(this.favoritos[i].usuario_seg);
        }

        this.toggleVisibilidadFavoritos();
      } else {
        this.mostrarMensajeModal("Favoritos", "Se ha producido un error al obtener el listado de favoritos");
      }
    })
  }

  getProductosFav() {
    const data = { lista: this.nProductosFav };
    this.pService.getProductosFav(data).subscribe(result => {
      this.favItems = result;
      if (this.favItems.length === 0) {
        this.tipoFav = "vacio";
      } else {
        this.tipoFav = "productos";
      }
      this.opcionFavs = "productos";
      // console.log(this.favItems);
    })
  }

  getPlatosFav() {
    const data = { lista: this.nPlatosFav };
    this.pService.getPlatosFav(data).subscribe(result => {
      this.favItems = result;
      if (this.favItems.length === 0) {
        this.tipoFav = "vacio";
      } else {
        this.tipoFav = "platos";
      }
      this.opcionFavs = "platos";
    })
  }

  getRestaurantesFav() {
    const data = { lista: this.nRestaurantesFav };
    this.pService.getRestaurantesFav(data).subscribe(result => {
      this.favItems = result;
      if (this.favItems.length === 0) {
        this.tipoFav = "vacio";
      } else {
        this.tipoFav = "restaurantes";
      }
      this.opcionFavs = "restaurantes";
      // console.log(this.favItems);
    })
  }

  getUsuariosFav() {
    const data = { lista: this.nUsuariosFav };
    this.pService.getUsuariosFav(data).subscribe(result => {
      this.favItems = result;
      if (this.favItems.length === 0) {
        this.tipoFav = "vacio";
      } else {
        this.tipoFav = "usuarios";
      }
      this.opcionFavs = "usuarios";
      // console.log(this.favItems);
    })
  }

  toggleVisibilidadPublicaciones() {
    this.visibilidadPublicaciones = !this.visibilidadPublicaciones;
  }
  toggleVisibilidadFavoritos() {
    this.visibilidadFavoritos = !this.visibilidadFavoritos;
    this.getProductosFav();
  }

  cerrarSesion = () => {
    localStorage.removeItem('user');
    this.router.navigateByUrl('login');
  }

  editarPerfil = () => {
    this.router.navigate(['editar-perfil']);
  }

  crearPagina = () => {
    this.router.navigate(['create-page'], { queryParams: { user: JSON.stringify(this.user) } });
  }

  reformatDate(dateStr) {
    if (dateStr != "" && dateStr != null) {
      let dArr = dateStr.split("-");
      return dArr[2] + "/" + dArr[1] + "/" + dArr[0];
    } else {
      return "";
    }
  }

  mostrarMensajeModal(titulo, mensaje) {
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(ModalMensajeComponent);
    this.refModalMensaje = this.entry.createComponent(factory);
    this.refModalMensaje.instance.mensaje = mensaje;
    this.refModalMensaje.instance.titulo = titulo;
    this.refModalMensaje.changeDetectorRef.detectChanges();
  }

  getFilledStars(calificacion: number) {
    const stars = [
      { value: 1, filled: false },
      { value: 2, filled: false },
      { value: 3, filled: false },
      { value: 4, filled: false },
      { value: 5, filled: false }
    ];

    for (let i = 0; i < stars.length; i++) {
      stars[i].filled = i < calificacion;
    }

    return stars;
  }

  goDetailPublicacion(item: string, id) {
    switch (item) {
      case 'publicacion':
        this.router.navigate(['publicacion/' + id]);
        break;
      case 'producto':
        this.router.navigate(['producto/' + id]);
        break;
      case 'plato':
        this.router.navigate(['plato/' + id]);
        break;
      case 'restaurante':
        this.router.navigate(['establecimiento/' + id]);
        break;
      default:
        break;
    }
  }
}
