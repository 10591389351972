import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { GLOBAL } from './global';


@Injectable({
  providedIn: 'root'
})
export class GetPhotosService {

  photo: any;
  base64Photos: any;

  constructor(
    private http: HttpClient,
    private domSanitizer: DomSanitizer
  ) {


  }

  getPhotosPortadaNoAsync(arr: any) {

    const ruta = GLOBAL.urlApi+"restaurante/getPhotosPortada";

    return this.http.put(ruta, arr);

  }

  async getPhotosPortada(selectedMap: any) {

    const ruta = GLOBAL.urlApi+"restaurante/getPhotosPortada";

    return await this.http.put(ruta, selectedMap).toPromise();

  }

  /**
   * POST PETITION FOR GET BASE64 IMG ARRAY
   * 
   * @param name nombre del restaurante
   * @param type tipo de fotos (banner, portada...)
   */
  public getPhotos(name: string, type: string) {

    console.log("*** realizando peticion ***");

    const ruta = GLOBAL.urlApi+"restaurante/getPhotos";

    const data =
    {
      name: name,
      type: type
    };
    return this.http.post(ruta, data);
  }

  /**
   * METHOD TO DECODE BASE64 PHOTO
   * @param photoBase64 
   */
  public decodeBase64ToImage(photoBase64: any) {

    // if (photoBase64 == undefined || photoBase64 == null) {
    //   return null;
    // } else {
    //   return this.photo = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpeg;base64,' + photoBase64);
    // }
    return this.photo = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpeg;base64,' + photoBase64);
  }
}
