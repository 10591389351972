export class User {
    id: number;
    nombre: string;
    email: string;
    password: string;
    foto_perfil: string;
    provincia: any;
    localidad: any;
    slug: string;
    politicas: boolean;
    mayor: boolean;
    nombre_usuario: string;
    novedades: boolean;
    dni: string;
    pais: string = "España";
}
