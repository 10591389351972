import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {

  personalizado: boolean = false;

  @Input() mensaje: string;

  isMobile: boolean = false;

  @ViewChild("divLoading", {static: false}) mensajeLoading: { nativeElement: any; };
  
  constructor(private deviceDetector: DeviceDetectorService) { }

  ngOnInit() {

    this.isMobile = this.deviceDetector.isMobile();
    
    if(this.mensaje == ''){
      this.personalizado = true;
    }
  }



}
