import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';

import { GLOBAL } from 'src/app/services/global';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ActivatedRoute } from '@angular/router';
import { PublicacionService } from 'src/app/services/publicacion.service';
import { PerfilService } from 'src/app/services/perfil.service';
import { ModalMensajeComponent } from 'src/app/components/general/modal-mensaje/modal-mensaje.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { RestauranteService } from 'src/app/services/restaurante.service';
import { Select2OptionData } from 'ng2-select2';
import { SelectModule } from 'ng2-select';
import { HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CropperComponent } from 'angular-cropperjs';
import Cropper from 'cropperjs';

@Component({
  selector: 'app-publicacion',
  templateUrl: './publicacion.component.html',
  styleUrls: ['./publicacion.component.css']
})
export class PublicacionComponent implements OnInit {
  isMobile: boolean = false;
  url: string;
  user: any;
  nombreRest: any = 'restaurante';
  nombrePlat: any = 'plato';
  nombreHM: any = 'marca';
  nombreHN: any = 'normal';
  refModalMensaje: any;
  publicacion: any = {};
  imagePublic: any;
  public imagePath;
  restaurantes: any = [];
  platos: any = [];
  hashtagsMarca: any = [];
  hashtagsNormal: any = [];
  public optionsHM: Select2Options;
  public optionsHN: Select2Options;
  public optionsRE: Select2Options;
  public optionsPA: Select2Options;
  fotoSubida: boolean = false;
  restauranteSeleccionado: string;
  nRate: number;

  cropperVisible: boolean = false;

  cropperOptions: any = {
    aspectRatio: 1,
    viewMode: 1,
    autoCrop: true,
    cropBoxResizable: false,
    dragMode: 'move',
    movable: false,
    toggleDragModeOnDblclick: false,
  };

  pantallaCarga: boolean = false;

  @ViewChild('modalMensaje', { read: ViewContainerRef, static: false }) entry: ViewContainerRef;
  @ViewChild('cropper', { static: false }) cropper: CropperComponent;

  constructor(private deviceDetector: DeviceDetectorService,
    private route: ActivatedRoute,
    private resolver: ComponentFactoryResolver,
    private pService: PublicacionService,
    private rService: RestauranteService,
    private perfService: PerfilService,) { }

  ngOnInit() {

    this.isMobile = this.deviceDetector.isMobile();

    this.url = GLOBAL.url;

    this.rService.getAllRestaurants().subscribe(result => {
      this.restaurantes = result;
    });

    this.pService.getAllHashtagMarca().subscribe(result => {
      this.hashtagsMarca = result;
    });

    this.pService.getAllHashtagNormal().subscribe(result => {
      this.hashtagsNormal = result;
    });

    this.optionsHM = {
      multiple: true,
      placeholder: 'Elija hashtag de marca',
      id: this.nombreHM,
    }

    this.optionsHN = {
      multiple: true,
      placeholder: 'Elija hashtag normal',
      id: this.nombreHN,
    }

    this.optionsRE = {
      placeholder: 'Elija un restaurante',
      id: this.nombreRest,
      allowClear: true,//x para quitar selección
      selectOnClose: false,
      closeOnSelect: false,
      dropdownAutoWidth: false,
      multiple: false,

    }

    this.optionsPA = {
      placeholder: 'Elija un plato',
      id: this.nombrePlat,
      allowClear: true,//x para quitar selección
      selectOnClose: false,
      closeOnSelect: false,
      dropdownAutoWidth: false,
      multiple: false,
    }

    this.route.queryParams.subscribe((params: any) => {
      this.user = JSON.parse(params.user_id);
      this.getPerfil(params.user_id);
    });

    this.route.params.subscribe(result => {
      console.log(result);

    });
  }

  getPerfil(userId: number) {
    this.perfService.getUserPerfil(userId).subscribe(result => {
      if (result.result == "ok") {
        this.user = result.user;
      } else {
        this.mostrarMensajeModal("Perfil", "Se ha producido un error al obtener los datos del perfil");
      }
    });
  }

  cancelarCropper() {
    this.imagePublic = null;
    this.fotoSubida = false;
    this.cropperVisible = false; // Ocultar el contenedor del cropper
  }

  aceptarCropper() {
    if (this.cropper && this.cropper.cropper) {
      const croppedCanvas = this.cropper.cropper.getCroppedCanvas();
      if (croppedCanvas) {
        this.imagePublic = croppedCanvas.toDataURL();
      }
    }
    this.cropperVisible = false;
  }

  cambiarImagen(file) {
    this.cropperVisible = true;
    this.getBase64(file[0]);
    this.fotoSubida = true;
  }

  getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.imagePublic = reader.result;

      /*
      this.pService.uploadImageUser(this.user.id,reader.result as string).subscribe(result => {
        if(result.result=="ok"){
          this.user.foto_perfil=result.image_url;
        }else{
          this.mostrarMensajeModal("Editar Imagen","Se ha producido un error al editar la imagen");
        }
        
      });*/
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  guardar() {
   
    if (this.validarForm()) {
      this.pantallaCarga = true;
      this.pService.addPublicacion(this.publicacion, this.imagePublic, this.user).subscribe(result => {
        if ((<any>result).result == "ok") {
          this.pantallaCarga = false;
          this.mostrarMensajeModal("Añadir publicación", "Se ha guardado los cambios.");
          this.publicacion.plato = this.publicacion.plato; // Asignar el valor seleccionado para "plato"
          this.publicacion.calificacion = this.nRate; // Asignar el valor seleccionado para "calificacion"
        }else {
          this.pantallaCarga = false;
          this.mostrarMensajeModal("Añadir publicación", "Se ha producido un error al insertar la publicación.");
        }
      })
    }
  }

  validarForm(){
    let camposValidar=["restaurante", "calificacion"];//"descripcion", "marca", "normal" ,
    let nombreCampos={ restaurante:"Restaurante ",  calificacion:"Calificación "};//descripcion:"Descripción ",marca:"Hashtah de Marca ", normal:"Hashtag normal ",
    let mensaje="Estos campos son obligatorios: ";
    // let mensajeImagen="Debe subir una imagen";
    let errorCampos=false;
    // let errorImagen=false;

    for(let i=0;i<camposValidar.length;i++){
      if(
        this.publicacion[camposValidar[i]]==undefined 
        // || this.publicacion[camposValidar[i]]=="" 
        // || this.publicacion[camposValidar[i]]==null
        // this.publicacion[camposValidar[i]].length == 0
        || this.publicacion[camposValidar[i]]==null
        ){
        mensaje+=nombreCampos[camposValidar[i]]+",";
        errorCampos=true;
      }

      // if(this.imagePublic == undefined 
      //   || this.imagePublic == ""
      //   || this.imagePublic == null) {
      //     mensajeImagen;
      //     errorImagen=true;
      //   }
    }

    if(!errorCampos  ){//&&!errorImagen
      return true;
    }else{
      if(errorCampos){
        mensaje=mensaje.substring(0,(mensaje.length-1));
        this.mostrarMensajeModal("Añadir publicación",mensaje);
        return false;
      }
      // if(errorImagen){
      //   mensajeImagen=mensajeImagen.substring(0,(mensajeImagen.length-1));
      //   this.mostrarMensajeModal("Añadir publicación",mensajeImagen);
      //   return false;
      // }
      
    }
  }

  mostrarMensajeModal(titulo, mensaje) {
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(ModalMensajeComponent);
    this.refModalMensaje = this.entry.createComponent(factory);
    this.refModalMensaje.instance.mensaje = mensaje;
    this.refModalMensaje.instance.titulo = titulo;
    this.refModalMensaje.changeDetectorRef.detectChanges();
  }

  reloadPage = () => {
    location.reload();
  }

  mostrarMensajeAddModal(titulo, mensaje) {
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(ModalMensajeComponent);
    this.refModalMensaje = this.entry.createComponent(factory);
    this.refModalMensaje.instance.mensaje = mensaje;
    this.refModalMensaje.instance.titulo = titulo;
    this.refModalMensaje.instance.goCerrar = this.reloadPage;
    this.refModalMensaje.changeDetectorRef.detectChanges();
  }


  getInfoProducto(id) {
    this.pService.getInfoPublicacion(id).subscribe(result => {
      console.log('publicacion', result);
      this.publicacion = result;
    });

  }

  onRestauranteSelect(restaurante: string) {
    this.restauranteSeleccionado = restaurante;
    this.getAllPlatosByRestaurante(restaurante);
  }

  getAllPlatosByRestaurante(restaurante: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const params = `restaurante=${restaurante}`;
    this.pService.getAllPlatos(params).subscribe(result => {
      if (Array.isArray(result)) {
        this.platos = [{ id: "", text: '-' }].concat(result.map(plato => ({ id: plato.id, text: plato.text })));
      } else {
        // Manejar el caso en el que result no sea un arreglo
      }
    });
  }

  onRatingChanged(rating: number) {
    // Aquí puedes realizar acciones con la calificación seleccionada
    //console.log('Calificación seleccionada:', rating);
    this.nRate = rating;
    this.publicacion.calificacion = rating;
    //console.log("Calificaión en variable", this.nRate);

  }
}

