export class Filtro {
    nombre: string;
    label: string;
    tag: string;
    
    tipoEstablecimiento: string;
    precio: string;
    take_away: string;
    ordenarPor: string;

    distancia:string;
    origenDeLosProductos:string;
    dieta:string;
    selectedProvincia:number
    posicionActual:any;
    

}
