import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { GLOBAL } from '../../services/global';
import { FollowService } from 'src/app/services/follow.service';
import { User } from 'src/app/models/user';
import { PublicacionService } from 'src/app/services/publicacion.service';
import { Publicacion } from 'src/app/models/publicacion.model';
import { UsuariosService } from 'src/app/services/usuarios.service';

@Component({
  selector: 'app-info-usuario',
  templateUrl: './info-usuario.component.html',
  styleUrls: ['./info-usuario.component.css']
})
export class InfoUsuarioComponent implements OnInit {

  usuario: User;
  publicacion: Publicacion
  arrayBotones = [];
  url = "";
  secciones = [];
  closeResult: string;
  nuevaSeccion: any;
  selectedFile: File = null;
  imageSrc: any;
  filesToUpload: any;
  file: any;
  textImg: any;
  textPrecio: string;
  urlCarta: string;
  qrOk: boolean = false;
  tempGuid: string;
  arrayCarta: any;
  cartaOk: boolean = false;
  tempSeccion: any;
  admin: boolean;
  visibilidadSecciones: Array<any> = [];
  follows: string;
  userLogin: any;
  resultInsertFollow: any;
  follow: boolean = false;

  @ViewChild("btnLike", null) btnLike: { nativeElement: any; }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private filService: FollowService,
    private pService: PublicacionService,
    private uService: UsuariosService
  ) {
    this.usuario = new User;
  }

  ngOnInit() {
    this.userLogin = JSON.parse(localStorage.getItem("user"));
    this.url = GLOBAL.url;
    this.admin = false;
    this.route.params.subscribe(result => {
      // this.usuario.id = result.id;
      // this.arrayBotones = GLOBAL.arrayBotonesUser(result.id);
      this.usuario.slug = result.slug;
      this.arrayBotones = GLOBAL.arrayBotonesUser(result.slug);


    });

    this.uService.getInfoUserBySlug(this.usuario.slug).subscribe(result => {
      console.log('loged', result);
      this.usuario = result;
    });

    setTimeout(() => {
      // this.pService.getInfoPublicacionUsuario(this.usuario.id).subscribe(result => {
      this.pService.getInfoPublicacionUsuario(this.usuario.id).subscribe(result => {
        console.log('result>', result);
        this.publicacion = result;
        this.generarImagenesSecciones(this.publicacion);


        console.log(this.publicacion);

        this.filService.getTotalSeguidoresUsuario(this.usuario.id).subscribe(result2 => {
          this.follows = result2;
        });
        // this.filService.setSeguidorUsuario(this.usuario.id, this.userLogin.id).subscribe(result => {
        //   this.resultInsertFollow = result;

        //   if(this.resultInsertFollow.result=="exists"){
        //     $(this.btnLike.nativeElement).html('<i class="fa fa-heart" aria-hidden="true"></i> Te gusta');
        //     $(this.btnLike.nativeElement).children('.fa-heart').addClass('animate-like');
        //   }
        // });

      });
    }, 500);

    setTimeout(() => {
      this.filService.comprobarFollowUser(this.usuario.id, this.userLogin.id).subscribe(resultado => {
        console.log(resultado);

        if (resultado.result == 'si') {
          this.follow = true;
          $(this.btnLike.nativeElement).html('<i class="fa fa-heart" aria-hidden="true"></i> Favorito');
          $(this.btnLike.nativeElement).children('.fa-heart').addClass('animate-like');
        }
        if (resultado.result == 'no') {
          this.follow = false;
          $(this.btnLike.nativeElement).html('<i class="fa fa-heart" aria-hidden="true"></i>Añadir a favorito');
          $(this.btnLike.nativeElement).children('.fa-heart').addClass('animate-like');
        }
      });
    }, 500);
  }

  generarImagenesSecciones = (publicacion) => {
    console.log('result>', publicacion);
    for (let i = 0; i < (publicacion).length; i++) {
      let fotosSeccion = [];

      fotosSeccion.push({
        thumbImage: GLOBAL.url + (publicacion)[i].img
        , descripcion: (publicacion)[i].descripcion
        , hashtag: (publicacion)[i].hashtag
        , nombreRestaurante: (publicacion)[i].restaurante.nombre
      });
      (publicacion)[i].fotoProductos = fotosSeccion;
    }
  }

  goDetailProducto(idTipo, item) {

    switch (item) {
      case 'carta': { this.router.navigate(['plato/' + idTipo]); break; }
      case 'productos': { this.router.navigate(['producto/' + idTipo]); break; }
      case 'restaurantes': { this.router.navigate(['establecimiento/' + idTipo]); break; }
      case 'usuarios': { this.router.navigate(['publicacion/' + idTipo]); break; }
    }
  }

  onClickLike() {
    this.filService.setSeguidorUsuario(this.usuario.id, this.userLogin.id).subscribe(result => {
      this.resultInsertFollow = result;

      if (this.resultInsertFollow.result == "ok") {
        this.follow = true;
        $(this.btnLike.nativeElement).html('<i class="fa fa-heart" aria-hidden="true"></i> Favorito');
        $(this.btnLike.nativeElement).children('.fa-heart').addClass('animate-like');
      }

      if (this.resultInsertFollow.result == "delete") {
        this.follow = false;
        $(this.btnLike.nativeElement).html('<i class="fa fa-heart" aria-hidden="true"></i>Añadir a favorito');
        $(this.btnLike.nativeElement).children('.fa-heart').addClass('animate-like');
      }
    });
  }

}
