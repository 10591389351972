import { Alergeno } from './alergeno';
import { Tags } from './tags';

export class Producto {
    guid: string;
    nombre: string;
    precio_producto: string;
    img: string;
    descripcion: string;
    id_fabricante: number;
    tagsMarca: string;
    alergenos: Alergeno[] = [];
    slug: string;
}
