import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Reserva } from '../models/reserva';
import { Observable } from 'rxjs';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class ReservasService {

  constructor(
    private http: HttpClient
  ) { }

  // AÑADIR NUEVA RESERVA A LARAVEL
  nuevaReserva(reserva: Reserva): Observable<any> {
    const json = JSON.stringify(reserva);
    const params = 'reserva=' + json;
    console.log(params);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'restaurante/nueva_reserva', params, { headers });
  }

  // OBTENER LISTADO DE RESERVAS DESDE LARAVEL
  getReservas(id: string): Observable<any> {
    const params = 'id_rest=' + id;
    console.log('params>', params);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'restaurante/getReservas', params, { headers });
  }

  // BORRAR RESERVA
  deleteReserva(id: string) {
    const params = 'tempId=' + id;
    console.log(params);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'restaurante/deleteReserva', params, { headers });
  }
}
