// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-rating',
//   templateUrl: './rating.component.html',
//   styleUrls: ['./rating.component.css']
// })
// export class RatingComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }

import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.css']
})
export class RatingComponent implements OnInit {
  stars: { value: number, filled: boolean }[] = [];

  @Output() ratingChanged = new EventEmitter<number>();

  ngOnInit() {
    // Inicializar las estrellas sin rellenar
    this.stars = [
      { value: 1, filled: false },
      { value: 2, filled: false },
      { value: 3, filled: false },
      { value: 4, filled: false },
      { value: 5, filled: false }
    ];
  }

  rate(value: number) {
    // Marcar las estrellas hasta el valor seleccionado como rellenadas
    this.stars.forEach(star => {
      star.filled = star.value <= value;
    });

    // Emitir el evento de calificación cambiada
    this.ratingChanged.emit(value);
  }
}
