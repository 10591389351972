import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { GLOBAL } from './global';
import { Plato } from '../models/plato';

@Injectable({
  providedIn: 'root'
})
export class MetricaService {

  constructor(
    private http: HttpClient
  ) { }

  headersJson = {
    "Accept": "application/json",
    "Content-Type": "application/json"
  };

  addMetrica(model: any): Observable<any> {
    const json = JSON.stringify(model);
    const params = json;
    return this.http.post(GLOBAL.urlApi+ 'metrica/add', params, {headers: this.headersJson});
  }
  
}

