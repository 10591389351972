import { Component } from '@angular/core';
import { UsuariosService } from './services/usuarios.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'uati';

  constructor(
    private uService: UsuariosService
  ) { }

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem("user"));

    if (user != null) {
      this.uService.actualizarNuevaConexion(user.id).subscribe(result => {
        if (result.result != "ok") {
          console.log("No esta guardando la ultima conexion");
        }

      });
    }
  }
}
