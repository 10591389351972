//import { SeccionResultadosComponent } from './../seccion-resultados/seccion-resultados.component';
import { Component, OnInit,NgModule } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GLOBAL } from '../../services/global';

@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.css']
})

export class InfoBoxComponent implements OnInit {
  nombre: string;
  tipo_local: string;
  tipo_cocina: string;
  horario_local: string;
  horario_desayuno: string;
  horario_comida: string;
  horario_cena: string;
  precio_medio_carta: string;
  reservas: string;
  cerrado: boolean;
  marcadores : any[];
  url = "";
  
  constructor(private route: ActivatedRoute) {
    this.marcadores = [{  
      nombre: 'test1',  
     
  }, {  
    nombre: 'test2',  
   
  }, {  
    nombre: 'test3',  
    
  }, {  
    nombre: 'test4',  
  
  },
  {  
    nombre: 'test5',  
  
  }, ];  
    //this.marcadores = this.seccionResultadosComponent.marcadores;
    // this.cerrado = false;
    // this.route.queryParams.subscribe(params => {
    //   this.nombre = params.nombre,
    //     this.tipo_local = params.tipo_local,
    //     this.tipo_cocina = params.tipo_cocina,
    //     this.horario_local = params.horario_local,
    //     this.horario_desayuno = params.horario_desayuno,
    //     this.horario_comida = params.horario_comida,
    //     this.horario_cena = params.horario_cena,
    //     this.precio_medio_carta = params.precio_medio_carta,
    //     this.reservas = params.reservas
    // });
  }

  ngOnInit() {
    // this.nombre= 'test2'
    // console.log(this.marcadores);
    this.url = GLOBAL.url;
  }

}