import { Component, OnInit,Input,ViewChild} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-modal-mensaje-url',
  templateUrl: './modal-mensaje-url.component.html',
  styleUrls: ['./modal-mensaje-url.component.css']
})
export class ModalMensajeUrlComponent implements OnInit {

  isMobile:boolean=false;

  @Input() titulo: string;
  @Input() mensaje: string;
  @Input() mensajeUrl: string;
  @Input() goCerrar: Function;
  @Input() boton: Function;

  // @Input() botones: { 
  //   accion: Function }[];

  @ViewChild("divTransparente",{static: false}) mensajeModalUrl: { nativeElement: any; };
  @ViewChild("divMensaje",{static: false}) divMensaje: { nativeElement: any; };

  constructor(private deviceDetector:DeviceDetectorService) {

  }

  ngOnInit() {
    this.isMobile=this.deviceDetector.isMobile();
  }



  cerrar=()=>{
    if(this.goCerrar!=undefined && this.goCerrar!=null){
      this.goCerrar();
    }else{
      $(this.mensajeModalUrl.nativeElement).css("display","none");
    }
    
  }

  abrirUrl(url: string) {
    window.open(url, '_blank');
  }
  
  ngAfterViewInit(){
    if(this.isMobile){
      $(this.divMensaje.nativeElement).css("width","90%");
    }
  }

}
