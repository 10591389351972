import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GLOBAL } from './global';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  headers: {
    Accept: string; "Content-Type": string; //,
  };

  constructor(
    private http: HttpClient
  ) {
    this.headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
    };
  }

  login(params: any) {
    return this.http.post(GLOBAL.urlApi + 'user/login', params, { headers: new HttpHeaders(this.headers) });
  }

  loginAuto(token: any) {
    let params={token:token}
    return this.http.post(GLOBAL.urlApi + 'user/loginAuto', params, { headers: new HttpHeaders(this.headers) });
  }

  registrarse(params: any) {
    return this.http.post(GLOBAL.urlApi + 'user/registro', params, { headers: new HttpHeaders(this.headers) });
  }

  recuperar(email: string): Observable<any> {
    const json = JSON.stringify({ email: email });
    const params = json;
  
    return this.http.post(GLOBAL.urlApi + 'user/recoverPassword', params, { headers: new HttpHeaders(this.headers) });
  }

  cambiarPass(codigo: string, pass: string, email: string): Observable<any>{
    const json = JSON.stringify({ codigo: codigo, pass: pass, email: email });
    const params = json;

    return this.http.post(GLOBAL.urlApi + 'user/changePassword', params, { headers: new HttpHeaders(this.headers) });
  }

  reenviarEmail(email: string){
    const json = JSON.stringify({email: email});
    const params = json;

    return this.http.post(GLOBAL.urlApi + 'user/reenviarEmail', params, { headers: new HttpHeaders(this.headers) });
  }
}
