import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { ModalMensajeComponent } from 'src/app/components/general/modal-mensaje/modal-mensaje.component';


@Component({
  selector: 'app-recuperar-pass',
  templateUrl: './recuperar-pass.component.html',
  styleUrls: ['./recuperar-pass.component.css'],
})
export class RecuperarPassComponent implements OnInit {

  email: string = '';
  tigger: boolean = false;
  codigo: string = '';
  pass: string = '';
  re_pass: string = '';
  mensajeActivador: boolean = false;
  mensajeError: boolean = false;
  mensajePassError: boolean = false;
  refModalMensaje: any;

  @ViewChild('modalMensaje', { read: ViewContainerRef, static: false }) entry: ViewContainerRef;

  constructor(private lService: LoginService, private router: Router, private resolver: ComponentFactoryResolver) { }

  ngOnInit() {

    this.mensajeActivador = false;
  }

  recuperar() {

    this.lService.recuperar(this.email).subscribe(result => {
      console.log(result);
      if (result.result == 'ok') {

        this.tigger = true;
      }

      if (result.result == 'errorNoExits') {

        this.tigger = false;
      }

    });

  }

  restablecer() {

    if (this.pass == this.re_pass) {

      this.mensajePassError = false;

      this.lService.cambiarPass(this.codigo, this.pass, this.email).subscribe(result => {

        //console.log("Resultado: " + result.result);

        // Si el servidor devuelve ok que muestre mensaje y redireccione al login
        if (result.result == 'ok') {

          //this.mensajeActivador = true;

          this.mostrarMensajeModal('Cambiar Contraseña', 'El cambio de contraseña se ha realizado correctamente');

        }
        if (result.result == 'errorNoIgual') {

          this.mensajeError = true;
        }

      });

    } else {

      this.mensajePassError = true;
    }
  }

  closeModalSuccess = () => {
    this.router.navigate(['/login']);
  }

  mostrarMensajeModal(titulo, mensaje) {
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(ModalMensajeComponent);
    this.refModalMensaje = this.entry.createComponent(factory);
    this.refModalMensaje.instance.mensaje = mensaje;
    this.refModalMensaje.instance.titulo = titulo;
    this.refModalMensaje.instance.goCerrar = this.closeModalSuccess;
    this.refModalMensaje.changeDetectorRef.detectChanges();
  }

  atras(){
    this.router.navigate(['/login']);
  }

}
