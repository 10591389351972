import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, RouterModule, Router } from '@angular/router';
import { PlatosService } from 'src/app/services/platos.service';
import { GLOBAL } from '../../services/global';
import { FollowService } from 'src/app/services/follow.service';
import { MetricaService } from 'src/app/services/metrica.service';
import { ModalMensajeComponent } from 'src/app/components/general/modal-mensaje/modal-mensaje.component';

declare const google: any;

@Component({
  selector: 'app-mapa-marca',
  templateUrl: './mapa-marca.component.html',
  styleUrls: ['./mapa-marca.component.css']
})
export class MapaMarcaComponent implements OnInit {

  url = "";
  pos: any = {};
  posMarkers: any = {};
  map: any;
  marca: string;
  refModalMensaje: any;
  //markers:Array<>=[];
  markers: any = {};
  mapaMarca: any = {};
  restaurantsArray: any = [];
  iconMyLocation: string = "/assets/img/my_location.png";
  iconComercio: string = "/assets/icons/comercio_.png";
  iconHosteleria: string = "/assets/icons/hosteleria_.png";
  iconCercaDeMi: string = "/assets/icons/cerca_de_mi.png";
  posIni: any = null;
  //directionsService = new google.maps.DirectionsService();
  //directionsRenderer = new google.maps.DirectionsRenderer();
  direcionOrigen: any = '';
  //btn_ruta = document.querySelectorAll('#click_ruta_ubicacion');
  btn_ruta: any;
  infowindow_aux = null;
  direccionTexto = "";


  @ViewChild('modalMensaje', { read: ViewContainerRef, static: false }) entry: ViewContainerRef;

  constructor(
    private route: ActivatedRoute,
    private pService: PlatosService,
    private fService: FollowService,
    private mService: MetricaService,
    private router: Router,
    private resolver: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {

    this.url = GLOBAL.url;
    this.restaurantsArray = [];


    this.route.params.subscribe(result => {
      this.marca = result.marca;
    });

    setTimeout(() => {
      this.showMarkersInMap('');
    }, 500);

  }

  addButtonGeolocation() {
    var geolocateButton = document.createElement('div');
    geolocateButton.style.backgroundImage = 'url("' + this.iconCercaDeMi + '")';
    geolocateButton.style.backgroundSize = 'cover';
    geolocateButton.style.cursor = "pointer";
    geolocateButton.style.width = "40px";
    geolocateButton.style.height = "40px";
    geolocateButton.style.marginRight = "10px";

    geolocateButton.addEventListener("click", () => {
      this.onClickCerca();
    });

    // Añadir el botón de geolocalización al mapa
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(geolocateButton);
  }

  showMarkersInMap(click: string) {
    if (this.infowindow_aux == null) {
      this.infowindow_aux = new google.maps.InfoWindow();
    }

    let bounds = new google.maps.LatLngBounds();

    this.pService.marcaValidaMapa(this.marca).subscribe(resultMapa => {
      if (resultMapa.result == "ok") {
        if (resultMapa.validado == 1) {
          this.mapaMarca = resultMapa.mapaMarca;

          //Metrica
          let modeloMetrica = {
            mapa_marca_id: this.mapaMarca.id,
            tipo_interaccion: 1,//Visualizacion
          }
          this.mService.addMetrica(modeloMetrica).subscribe(result => {
            if (result.result == "ok") {
              console.log("Visualizacion mapa marca guardada en metrica");
            } else {
              console.log("Error a la hora de guardar visualizacion mapa marca en metrica");
            }
          });

          this.pService.getPlatosByMarca(this.marca).subscribe(result => {
            this.restaurantsArray = result;
            for (let i = 0; i < (<any>this.restaurantsArray).length; i++) {
              let device = this.restaurantsArray[i];
              if (i == 0) {
                this.map = new google.maps.Map(document.getElementById('map'), {
                  center: {
                    lat: parseFloat(device.restaurante.lat),
                    lng: parseFloat(device.restaurante.lng)
                  },
                  zoom: 10,
                  mapTypeControl: false,
                  streetViewControl: true,
                  clickableIcons: false,
                  mapTypeControlOptions: {
                    mapTypeIds: []
                  },
                });

                this.addButtonGeolocation();
              }

              if (i == (<any>this.restaurantsArray).length - 1) {
                if (click == 'clickAqui') {
                  if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position) => {
                      this.pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                      };

                      /*
                      this.map = new google.maps.Map(document.getElementById('map'), { 
                        center: this.pos,
                        zoom: 10,
                        streetViewControl: true,
                        clickableIcons: false
                      });
                      */

                      new google.maps.Marker({
                        position: this.pos,
                        map: this.map,
                        icon: {
                          url: this.iconMyLocation,
                          size: new google.maps.Size(20, 20),
                          scaledSize: new google.maps.Size(20, 20),
                          labelOrigin: new google.maps.Point(20, -20)
                        },
                      });

                      this.map.setCenter(this.pos);
                      this.map.setZoom(15);

                      //bounds.extend(this.pos);
                      //this.map.fitBounds(bounds);
                    });
                  }
                }

              }
              this.posMarkers = {
                lat: parseFloat(device.restaurante.lat),
                lng: parseFloat(device.restaurante.lng)
              };

              let icon = null;

              if (device.restaurante.tiposlocales.length > 0) {
                let entidad = device.restaurante.tiposlocales[0].tipo_entidad.id;

                switch (entidad) {
                  case 1: {
                    icon = this.iconHosteleria;
                    break;
                  }
                  case 2: {
                    icon = this.iconComercio;
                    break;
                  }
                }
              }

              this.markers[device.restaurante.id] = new google.maps.Marker({
                position: this.posMarkers,
                map: this.map,
                icon: {
                  url: icon,
                  size: new google.maps.Size(40, 40),
                  scaledSize: new google.maps.Size(40, 40),
                  labelOrigin: new google.maps.Point(40, -40)
                },
              });

              bounds.extend(this.posMarkers);
              this.map.fitBounds(bounds);
              var noPoi = [
                {
                  featureType: "poi",
                  stylers: [
                    { visibility: "off" }
                  ]
                }
              ];
              this.map.setOptions({ styles: noPoi });



              this.markers[device.restaurante.id].addListener("click", () => {
                //Metrica
                let modeloMetrica = {
                  mapa_marca_id: this.mapaMarca.id,
                  establecimiento_id: device.restaurante.id,
                  tipo_interaccion: 1,//Visualizacion
                }

                if (device.establecimiento == undefined) {
                  modeloMetrica["articulo_id"] = device.id;
                }

                this.mService.addMetrica(modeloMetrica).subscribe(result => {
                  if (result.result == "ok") {
                    console.log("Visualizacion establecimiento mapa marca guardada en metrica");
                  } else {
                    console.log("Error a la hora de guardar visualizacion establecimiento mapa marca en metrica");
                  }
                });

                this.selectedDevice(device.restaurante);
                this.infoWindow(device, this.markers[device.restaurante.id]);
              });

            }
          });

          if (this.btn_ruta != null) {
            this.btn_ruta.addEventListener("click", function (event) {
              console.log(event);

            });
          }
        }
      } else {
        this.mostrarMensajeModal("Error validar mapa marca", "Se ha producido un error a la hora de validar el mapa marca.");
      }

    });
  }

  seeMyLocation = () => {

    let bounds = new google.maps.LatLngBounds();

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        /*
        this.map = new google.maps.Map(document.getElementById('map'), { 
          center: this.pos,
          zoom: 10,
          streetViewControl: true,
          clickableIcons: false
        });
        */

        new google.maps.Marker({
          position: this.pos,
          map: this.map,
          icon: {
            url: this.iconMyLocation,
            size: new google.maps.Size(20, 20),
            scaledSize: new google.maps.Size(20, 20),
            labelOrigin: new google.maps.Point(20, -20)
          },
        });

        bounds.extend(this.pos);
        this.map.fitBounds(bounds);
      });
    }
  }


  seeAllMarker(cad: string) {
    let bounds = new google.maps.LatLngBounds();

    for (let i = 0; i < this.restaurantsArray.length; i++) {
      if (this.markers[this.restaurantsArray[i].restaurante.id] != undefined) {
        let pos = this.markers[this.restaurantsArray[i].restaurante.id].getPosition();
        if ((!isNaN(pos.lat())) && (!isNaN(pos.lng()))) {
          bounds.extend(pos);
        }
      }
    }

    this.map.fitBounds(bounds);
    /*
        if(cad == 'clickAqui'){
          bounds.extends(this.pos);
          this.map.fitBounds(bounds);
        }
    */

  }

  chargeMap = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        this.map = new google.maps.Map(document.getElementById('map'), {
          center: this.pos,
          zoom: 10,
          mapTypeControl: false,
          streetViewControl: true,
          clickableIcons: false,
          mapTypeControlOptions: {
            mapTypeIds: []
          }
        });

        this.addButtonGeolocation();

        new google.maps.Marker({
          position: this.pos,
          map: this.map,
          icon: {
            url: this.iconMyLocation,
            size: new google.maps.Size(20, 20),
            scaledSize: new google.maps.Size(20, 20),
            labelOrigin: new google.maps.Point(20, -20)
          },
        });
      });
    } else {
      alert("Browser doesn't support Geolocation");
    }
  }

  selectedDevice(device: any) {
    if (this.markers[device.id] != undefined) {
      var pos = {
        lat: parseFloat(device.lat),
        lng: parseFloat(device.lng)
      };

      this.map.setCenter(pos);
    }
  }


  infoWindow(device, marker) {
    var contenidoInfo = '';
    contenidoInfo = '<div style="display:flex;flex-direction: column;">';
    contenidoInfo += '<div style="flex:1;">';
    contenidoInfo += '<img style="width: 100%;height: 100%;object-fit: contain;" src="' + GLOBAL.url + device.img + '" onerror="this.onerror=null; this.src=\'' + GLOBAL.url + 'no-photo-available.png\'" />';
    contenidoInfo += '</div>';
    contenidoInfo += '<div style="flex:1;padding:5px;">';
    contenidoInfo += '<label style="margin-left: 0px; font-size: 15px;font-weight: bold;flex:1;margin-bottom: 0px;">';
    contenidoInfo += device.nombre_plato;
    contenidoInfo += '</label>';
    contenidoInfo += '<p style="margin-top: 5px;font-size: 14px;color: #E35100;margin-bottom: 5px;">';
    contenidoInfo += '<b>' + device.restaurante.nombre + '</b>';
    contenidoInfo += '</p>';
    /*
     if (device.restaurante.tipo_comidas != null) {
       contenidoInfo += '<p style="font-size: 12px;color: #E35100;">';
       contenidoInfo += '<b>' + device.restaurante.tipo_comidas + '</b>';
       contenidoInfo += '</p>';
     }
     
     contenidoInfo += '<p>';
     contenidoInfo += '<b>' + device.restaurante.direccion + '</b></p>';
     */

    this.codeAddress(device.restaurante.lng, device.restaurante.lat).then(() => {
      let url = ""

      contenidoInfo += '<div id="history_dir">' + this.direccionTexto + '</div>';
      contenidoInfo += '<div style="display: flex;align-items: center;">';
      /*if (localStorage.getItem("user") === null) {
        url = '/login/' + device.slug + '?nombreMarca=' + this.marca + '&restauranteClickcado=' + device.restaurante.id + '&platoClickcado=' + device.id + '&marcaId=' + this.mapaMarca.id;
        contenidoInfo += '<a target="_blank" style="color:white;padding: 4px; border-radius: 5px; background-color:#E35100;padding: 5px 10px 5px 10px;" href="' + url + '">VER</a>';
        contenidoInfo += '<a style="font-size: 27px;margin-left: 20px;color: #E35100;"  href="' + url + '"><i class="far fa-bookmark" style=""></i></a>';
      } else {*/
      let usuario = localStorage.getItem('user');


      //}
      //contenidoInfo += '<button style="color:white; padding:4px; border-radius: 5px; background-color: #E35100; margin-left: 5px;" id="btn_ruta_google">RUTA</button>';
      contenidoInfo += '</div>';


      contenidoInfo += '</div>';
      contenidoInfo += '<div style="    display: flex;flex-direction: row;justify-content: space-between;">';
      if (device.establecimiento != undefined) {
        url = '/establecimiento/' + device.slug + '?nombreMarca=' + this.marca;

        if (usuario != null) {
          let id = JSON.parse(usuario).id;
          url += '&addFavorite=' + id;
        }

        url += '&restauranteClickcado=' + device.restaurante.id + '&marcaId=' + this.mapaMarca.id;
        contenidoInfo += '<a target="_blank" style="padding-top: 5px;padding-bottom: 5px;font-size: 16px;font-weight: bold;color: white;background-color: #E35100;flex: 1;display: flex;justify-content: center;align-items: center;" href="' + url + '">Ver +</a>';
        //contenidoInfo += '<a style="margin-left: -1px;font-size: 22px;padding-right: 10px;color: #FFF;background-color: #E35100;"  href="' + url + '"><i class="far fa-bookmark" style=""></i></a>';

        //<i class="far fa-bookmark iGuardar" (click)="onClickLike()" *ngIf="!follow"></i>

      } else {

        url = '/plato/' + device.slug + '?nombreMarca=' + this.marca;

        if (usuario != null) {
          let id = JSON.parse(usuario).id;
          url += '&addFavorite=' + id;
        }

        url += '&restauranteClickcado=' + device.restaurante.id + '&platoClickcado=' + device.id + '&marcaId=' + this.mapaMarca.id;
        contenidoInfo += '<a target="_blank" style="padding-top: 5px;padding-bottom: 5px;font-size: 16px;font-weight: bold;color: white;background-color: #E35100;flex: 1;display: flex;justify-content: center;align-items: center;" href="' + url + '">Ver +</a>';
        //contenidoInfo += '<a style="margin-left: -1px;font-size: 22px;padding-right: 10px;color: #FFF;background-color: #E35100;"  href="' + url + '"><i class="far fa-bookmark" style=""></i></a>';
      }
      contenidoInfo += '</div>';
      contenidoInfo += '</div>';


      this.infowindow_aux.setContent(contenidoInfo);
      this.infowindow_aux.setOptions({ maxWidth: 450 });
      this.infowindow_aux.open(this.map, marker);

      google.maps.event.addListener(this.infowindow_aux, 'domready', () => {
        var iwOuter = $('.gm-style-iw-d');
        iwOuter.parent()[0].attributes[3].nodeValue = 'max-width: 350px; max-height: 635px; min-width: 0px;'
        const someButton = document.getElementById('btn_ruta_google');
        if (someButton) {
          someButton.addEventListener('click', (event: any) => {
            console.log(event);
          })
        }

      });
    });

  }

  async codeAddress(long: any, lat: any) {
    return new Promise<void>((resolve, reject) => {
      var service = new google.maps.DistanceMatrixService();

      var html = '';
      this.direccionTexto = "";

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {

          var origen = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
          var destino = new google.maps.LatLng(lat, long);

          service.getDistanceMatrix(
            {
              origins: [origen],
              destinations: [destino],
              travelMode: google.maps.TravelMode.WALKING,
              unitSystem: google.maps.UnitSystem.METRIC,
              avoidHighways: false,
              avoidTolls: false,

            }, (response, status) => {
              if (status == 'OK') {
                html += '<div style="display: flex;align-items: center;">';
                html += '<div style="width:60px;color:#E35100;border-radius: 0px;background-color: #FFF;border:solid 2px #E35100;border-radius: 4px;padding: 0px 0px 0px 4px;font-weight: bold;font-size: 18px;" onclick="window.open(\'https://www.google.com/maps/dir/?api=1&origin=' + position.coords.latitude + ',' + position.coords.longitude + '&destination=' + lat + ',' + long + '&travelmode=walking\');">Ir a<img style="width:23px;" src=\'../../../assets/img/google_map.png\' /></div>';
                html += '<label style="margin-bottom: 0px;margin-left: 10px;flex:1;"><b>A ' + response.rows[0].elements[0].distance.text + '</b></label>';
                html += '</div>';
                html += '<div style="color: green;margin-bottom: 0px;margin-top: 1px;display: flex;align-items: center;">';
                html += '<img style="width: 15%;height: 100%;object-fit: cover;" src="../../../assets/icons/icono_andando.png" />'
                html += '<label style="margin-bottom:0px;">' + response.rows[0].elements[0].duration.text + '</label>';
                html += '</div>';

              }

              this.direccionTexto = html;
              resolve();
            });

        }, (error) => {
          this.direccionTexto = '<p><b>Sin permisos en ubicación</b></p>';
          resolve();
        });

      }
    });

  }

  codeAddressDestine(address: any) {
    if (address != null && address != undefined) {
      return address;
    }
  }

  onClickCerca() {
    this.showMarkersInMap('clickAqui');
  }

  mostrarMensajeModal(titulo, mensaje) {
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(ModalMensajeComponent);
    this.refModalMensaje = this.entry.createComponent(factory);
    this.refModalMensaje.instance.mensaje = mensaje;
    this.refModalMensaje.instance.titulo = titulo;
    this.refModalMensaje.changeDetectorRef.detectChanges();
  }

}
