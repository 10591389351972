import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class MapaService { 

  private messageSource = new BehaviorSubject([])
  currentMessage = this.messageSource.asObservable();


  private filtros = new BehaviorSubject({})
  currentFiltros = this.filtros.asObservable();

  constructor(private http: HttpClient) {}

  getProvincias(): Observable<any> {
    return this.http.get(GLOBAL.urlApi+'provincias');
  }

  getMunicipios(): Observable<any> {
    return this.http.get(GLOBAL.urlApi+'municipios');
  }

  getSubEntidades(): Observable<any> {
    return this.http.get(GLOBAL.urlApi+'subentidades');
  }
  
  /*getNumMarcadores() {
    return this.marcadores;
  }
  
  async setNumMarcadores(marcadores: number) {
    this.marcadores = marcadores;
  }*/

  changeMessage(marcadores: any) {
    this.messageSource.next(marcadores)
  }

  changeFiltros(filtros: {}){

    this.filtros.next(filtros);
    console.log("next");
    
  }
}
