import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class IsLoginGuard implements CanActivate {

  constructor(
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (localStorage.getItem("user") === null) {
      //this.router.createUrlTree(['/login'], { queryParams: { returnUrl: state.url } });
      this.router.navigate(['/registrarse'], { queryParams: { returnUrl: state.url } });
      return false;
    }
    return true;
  }
}
