import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Producto } from 'src/app/models/producto.model';
import { Fabricante } from 'src/app/models/fabricante.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductoService } from 'src/app/services/producto.service';
import { FollowService } from 'src/app/services/follow.service';
import { RestauranteService } from 'src/app/services/restaurante.service';
import { GLOBAL } from '../../services/global';
import { Router } from '@angular/router';
import { MetricaService } from 'src/app/services/metrica.service';

declare const google: any;

@Component({
  selector: 'app-pagina-producto',
  templateUrl: './pagina-producto.component.html',
  styleUrls: ['./pagina-producto.component.css']
})
export class PaginaProductoComponent implements OnInit {

  arrayBotones = [];
  producto: Producto;
  fabricante: Fabricante;
  urlResources = GLOBAL.urlResourcesCarta;
  urlIcons = GLOBAL.urlIcons;
  url = "";
  closeResult: string;
  tagsMarca = [];
  tagsNormal = [];
  alergenos = [];
  newTagNormal: string;
  hashtagProducto: string;
  hashtagProductoSin: string;
  listTagNormal = [];
  tempMarca: any[];
  tempNormal: any[];
  follows: string;
  userLogin: any;
  idProducto: number;
  slugProducto: any;
  slugFabricante: any;
  resultInsertFollow: any;
  urlTags: boolean = false;
  esInformacion: boolean = true;
  follow: boolean = false;
  urlContactoWhatsapp: string = "";
  pantallaCarga: boolean = false;

  //Para el mapa
  map: any;
  isMobile: any;
  restaurantsArray: any = [];
  markers: Array<any> = [];
  pos: any = null;
  iconMyLocation: string = "/assets/img/my_location.png";

  @ViewChild("btnLike", null) btnLike: { nativeElement: any; }

  constructor(
    private route: ActivatedRoute,
    private pService: ProductoService,
    private fService: FollowService,
    private resService: RestauranteService,
    private router: Router,
    private mService: MetricaService
  ) {
    this.producto = new Producto;
    this.fabricante = new Fabricante;
  }

  ngOnInit() {
    this.userLogin = JSON.parse(localStorage.getItem("user"));
    this.esInformacion = true;

    this.url = GLOBAL.url;
    this.route.params.subscribe(result => {
      // this.idProducto=result.id;
      // this.getInfoProducto(result.id);  
      this.slugProducto = result.slug;
      this.getInfoProducto(result.slug);
    });

    let currentUrl = window.location.href;
    if (currentUrl.indexOf("tags") != -1) {
      this.esInformacion = false;
      this.urlTags = true;
      // this.arrayBotones = GLOBAL.arrayBotonesQR(this.idProducto);
      // this.irMapaProducto(this.idProducto);
      this.arrayBotones = GLOBAL.arrayBotonesQR(this.slugProducto);
      this.irMapaProducto(this.slugProducto);
    }

    if (this.userLogin != null) {
      setTimeout(() => {
        this.fService.comprobarFollowProd(this.idProducto, this.userLogin.id).subscribe(resultado => {

          if (resultado.result == 'si') {
            this.follow = true;
            $(this.btnLike.nativeElement).html('<i class="fa fa-bookmark" aria-hidden="true"></i> Producto guardado');
            $(this.btnLike.nativeElement).children('.fa-bookmark').addClass('animate-like');
          }
          if (resultado.result == 'no') {
            this.follow = false;
            $(this.btnLike.nativeElement).html('<i class="fa fa-bookmark" aria-hidden="true"></i> Guardar producto');
            $(this.btnLike.nativeElement).children('.fa-bookmark').addClass('animate-like');
          }
        });
      }, 500);
    }

  }

  clickIrA() {
    this.pantallaCarga = true;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        const url = `https://www.google.com/maps/dir/?api=1&origin=${this.pos.lat},${this.pos.lng}&destination=${this.fabricante.lat},${this.fabricante.lng}&travelmode=walking`;
        window.open(url, '_blank');
        this.pantallaCarga = false;
      }, (error) => {
        console.error("Error obteniendo la ubicación: ", error);
        this.pantallaCarga = false;
      });
    }
  }

  convertirSaltosDeLinea(texto) {
    return texto.replace(/\r\n|\r|\n/g, '<br>');
  }

  onClickLike() {

    if (this.userLogin == null) {
      this.router.navigate(['registrarse'], { queryParams: { marcaFicha: this.producto.nombre } });
    } else {
      this.fService.setSeguidorProducto(this.idProducto, this.userLogin.id).subscribe(result => {
        this.resultInsertFollow = result;

        if (this.resultInsertFollow.result == "ok") {
          this.follow = true;
          $(this.btnLike.nativeElement).html('<i class="fa fa-bookmark" aria-hidden="true"></i> Producto guardado');
          $(this.btnLike.nativeElement).children('.fa-bookmark').addClass('animate-like');
        }

        if (this.resultInsertFollow.result == "delete") {
          this.follow = false;
          $(this.btnLike.nativeElement).html('<i class="fa fa-bookmark" aria-hidden="true"></i> Guardar producto');
          $(this.btnLike.nativeElement).children('.fa-bookmark').addClass('animate-like');
        }

        this.getTotalSeguidores();
      });
    }

  }

  onClickPedidos() {
    this.addMetrica(8);

    if (this.userLogin == null) {
      this.router.navigate(['registrarse'], { queryParams: { accion: "pedido", marcaFicha: this.fabricante.nombre } });
    } else {
      const url = this.fabricante.website;
      window.open(url, '_blank');
    }

  }

  // OBTENER LISTADO DE PLATOS DESDE LARAVEL
  getInfoProducto(id) {
    // this.pService.getInfoProducto(id).subscribe(result => {
    this.pService.getInfoProductoBySlug(id).subscribe(result => {
      this.producto = result;
      this.idProducto = result.id;
      this.producto.id_fabricante = JSON.parse(result.id_fabricante);
      this.slugFabricante = result.fabricante.slug;
      this.producto.descripcion = result.descripcion;
      this.tempMarca = result.hashtag;
      this.producto.alergenos = JSON.parse(result.alergenos);
      this.hashtagProductoSin = result.hashtag.replace('#', '');

      //Se inicializa la variable restaurante
      this.fabricante.id = this.producto.id_fabricante;
      this.fabricante.fotoPortada = result.fabricante.fotoPortada;
      this.fabricante.nombre = result.fabricante.nombre;
      this.fabricante.direccion = result.fabricante.direccion;
      this.fabricante.take_away = result.fabricante.take_away;
      this.fabricante.website = result.fabricante.website;
      this.fabricante.provincia = result.fabricante.provincia;
      this.fabricante.municipio = result.fabricante.municipio;
      this.fabricante.slug = result.fabricante.slug;
      this.fabricante.lng = result.fabricante.lng;
      this.fabricante.lat = result.fabricante.lat;

      // Botones del menú principal
      // this.arrayBotones = GLOBAL.arrayBotonesFabri(this.producto.id_fabricante);
      this.arrayBotones = GLOBAL.arrayBotonesFabri(this.slugFabricante);
      this.getTotalSeguidores();
      this.addMetrica(1);
    });
  }

  addMetrica(tipo_interaccion) {

    let modeloMetrica = {
      producto_id: this.idProducto,
      fabricante_id: this.fabricante.id,
      tipo_interaccion: tipo_interaccion
    }

    this.mService.addMetrica(modeloMetrica).subscribe(result => {
      if (result.result == "ok") {
        console.log("Metrica guardada");
      } else {
        console.log("Error a la hora de guardar en metrica");
      }
    });

  }

  getTotalSeguidores = () => {
    //Nos traemos los seguidores que tiene este plato
    this.fService.getTotalSeguidoresProductos(this.producto.slug).subscribe(result2 => {
      this.follows = result2;
    });
  }

  irMapaProducto($id) {
    //Nos traemos todos los restaurantes que tengan como hashtag de marca este producto
    // this.pService.getInfoProducto($id).subscribe(result => {
    this.pService.getInfoProductoBySlug($id).subscribe(result => {
      this.hashtagProducto = result.hashtag;
      this.chargeMap();
      this.resService.getAllRestaurantsProductoMapa(this.hashtagProducto).subscribe(result => {
        this.restaurantsArray = result;
        this.runPrintMap();
      });
    });



  }

  chargeMap = () => {

    this.map = new google.maps.Map(document.getElementById('map'), {
      zoom: 18,
      streetViewControl: true,
      clickableIcons: false
    });

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        this.map = new google.maps.Map(document.getElementById('map'), {
          center: this.pos,
          zoom: 6,
          streetViewControl: true,
          clickableIcons: false
        });

        new google.maps.Marker({
          position: this.pos,
          map: this.map,
          icon: {
            url: this.iconMyLocation,
            size: new google.maps.Size(20, 20),
            scaledSize: new google.maps.Size(20, 20),
            labelOrigin: new google.maps.Point(20, -20)
          },
        });

      });
    } else {
      alert("Browser doesn't support Geolocation");
    }
  }

  runPrintMap = () => {
    for (let i = 0; i < (<any>this.restaurantsArray).length; i++) {
      let restaurant = this.restaurantsArray[i];
      this.markers[restaurant.id] = new google.maps.Marker({
        position: { lat: parseFloat(restaurant.lat), lng: parseFloat(restaurant.lng) },
        map: this.map
      });

      this.markers[restaurant.id].addListener("click", () => {
        this.selectedDevice(restaurant);
        this.infoWindow(restaurant, this.markers[restaurant.id]);
      });
    }

    if (this.pos == null) {
      this.seeAllMarker();
    }


  }

  seeAllMarker() {
    let bounds = new google.maps.LatLngBounds();

    for (let i = 0; i < this.restaurantsArray.length; i++) {
      if (this.markers[this.restaurantsArray[i].id] != undefined) {
        bounds.extend(this.markers[this.restaurantsArray[i].id].getPosition());
      }
    }

    this.map.fitBounds(bounds);
  }

  selectedDevice(device: any) {
    if (this.markers[device.id] != undefined) {
      var pos = {
        lat: parseFloat(device.lat),
        lng: parseFloat(device.lng)
      };
      //this.map.setZoom(20);
      this.map.setCenter(pos);
    }
  }

  infoWindow(device, marker) {

    var contenidoInfo = '<div style="display:flex;">' +
      '<div style="flex:1;">' +
      '<img style="width: 100%;height: 100%;object-fit: cover;" src="' + GLOBAL.url + device.fotoPerfil + '" onerror="this.onerror=null; this.src=\'' + GLOBAL.url + 'no-photo-available.png\'" />' +
      '</div>' +
      '<div style="flex:1;margin-left: 10px;">' +
      '<label style="margin-left: 0px; font-size: 15px;font-weight: bold;flex:1;margin-bottom: 0px;">' +
      device.nombre +
      '</label>' +
      '<p style="margin-top: 10px;font-size: 12px;color: #E35100;">' +
      '<b>' + device.tipo_local + '</b>' +
      '</p>' +
      '<p style="font-size: 12px;color: #E35100;">' +
      '<b>' + device.tipo_comidas + '</b>' +
      '</p>' +
      '<p>' +
      '<b>' + device.direccion + '</b>' +
      '</p>' +
      '<button style="color:white;padding: 4px;border-radius: 0px; background-color:#E35100" onclick="window.location.href = \'establecimiento/' + device.id + '\';">Ver Carta</button>' +
      '</div>'
    '</div>';
    var infowindow = new google.maps.InfoWindow();
    infowindow.setContent(contenidoInfo);
    infowindow.setOptions({ maxWidth: 450 });
    infowindow.open(this.map, marker);
  }

  clickHahstag = (url, mapa = false) => {
    this.router.navigateByUrl(url);

    if (mapa) {
      this.addMetrica(9);
    }
  }

  navegar(direccion) {
    this.router.navigate([direccion]);
  }

}
