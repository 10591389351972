import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PerfilService } from 'src/app/services/perfil.service';
import { GLOBAL } from 'src/app/services/global';
import { ModalMensajeComponent } from 'src/app/components/general/modal-mensaje/modal-mensaje.component';
import { ModalConfirmComponent } from '../general/modal-confirm/modal-confirm.component';
import { MapaService } from '../../services/mapa.service';

@Component({
  selector: 'app-editar-perfil',
  templateUrl: './editar-perfil.component.html',
  styleUrls: ['./editar-perfil.component.css']
})
export class EditarPerfilComponent implements OnInit {

  url: string;
  user: any;
  userLogin: any;
  isMobile: boolean;
  refModalMensaje: any;
  rolUsuario: number[] = [];
  provincias: any[];
  municipios: any[];
  optionsSelect: Select2Options = {};
  optionsSelectMultiple: Select2Options = { multiple: true };
  selectedProvincia: any = null;
  valueProvincia: any = null;
  valueMunicipio: any = null;
  valueSexo: any = null;
  filteredMunicipios: any[];
  validCif: boolean = false;
  tiposDieta: Array<any> = [];
  tiposSexo: Array<any> = [];
  alergenos: Array<any> = [];
  valueDietas: Array<any> = [];
  valueAlergias: Array<any> = [];

  @ViewChild('modalMensaje', { read: ViewContainerRef, static: false }) entry: ViewContainerRef;

  constructor(private route: ActivatedRoute,
    private deviceDetector: DeviceDetectorService,
    private pService: PerfilService,
    private router: Router,
    private resolver: ComponentFactoryResolver,
    private mapService: MapaService) { }

  ngOnInit() {
    this.isMobile = this.deviceDetector.isMobile();
    this.url = GLOBAL.url;
    this.userLogin = JSON.parse(localStorage.getItem("user"));

    this.getPerfil(this.userLogin.id);

  }

  getPerfil(userId: number) {
    this.pService.getUserPerfil(userId).subscribe(result => {

      console.log(result);

      if (result.result == "ok") {

        this.user = result.user;
        this.alergenos = result.alergenos;
        this.tiposSexo = result.tiposSexo;
        this.tiposDieta = result.tiposDieta;

        this.valueSexo = JSON.parse(JSON.stringify(this.user.tipoSexoId));
        this.valueDietas = JSON.parse(JSON.stringify(JSON.parse(this.user.tiposDieta)));
        this.valueAlergias = JSON.parse(JSON.stringify(JSON.parse(this.user.alergias)));

        this.mapService.getProvincias().subscribe((provincias) => {
          provincias.unshift({ id: "", text: "" });
          this.provincias = provincias;
          let userProvincia = JSON.parse(JSON.stringify(this.user.municipio));

          if (userProvincia != null) {
            this.valueProvincia = JSON.parse(JSON.stringify(this.user.municipio.provincia.id));
          }

        });

        this.mapService.getMunicipios().subscribe((municipios) => {
          municipios.unshift({ id: '', text: "" });
          this.municipios = municipios;

          if (this.user.municipio != null) {
            if (this.user.municipio.provincia.id != undefined && this.user.municipio.provincia.id != null && this.user.municipio.provincia.id != 0) {
              this.onProvinciaChange({ value: this.user.municipio.provincia.id });
              this.valueMunicipio = JSON.parse(JSON.stringify(this.user.municipio.id));
            }
          }

        });

        this.getRolUser(this.user.id);

      } else {
        this.mostrarMensajeModal("Perfil", "Se ha producido un error al obtener los datos del perfil");
      }
    });
  }

  cambiarImagen(file) {
    this.getBase64(file[0]);
  }

  getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.pService.uploadImageUser(this.user.id, reader.result as string).subscribe(result => {
        if (result.result == "ok") {
          this.user.foto_perfil = result.image_url;
          localStorage.setItem("user", JSON.stringify(this.user));
        } else {
          this.mostrarMensajeModal("Editar Imagen", "Se ha producido un error al editar la imagen");
        }

      });
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  guardar() {
    if (this.validarForm()) {
      console.log(this.user);
      this.pService.editarPerfil(this.user).subscribe(result => {
        if ((<any>result).result == "ok") {
          this.user = (<any>result).user;
          localStorage.setItem("user", JSON.stringify(this.user));
          this.mostrarMensajeModal("Editar Perfil", "Se ha guardado los cambios.");
        } else if ((<any>result).result == "exist") {
          this.mostrarMensajeModal("Editar Perfil", "No se ha guardado los cambios porque el email ya existe.");
        } else if ((<any>result).result == "existUsuario") {
          this.mostrarMensajeModal("Editar Perfil", "No se ha guardado los cambios porque el nombre de usuario ya existe.");
        } else {
          this.mostrarMensajeModal("Editar Perfil", "Se ha producido un error al editar el perfil.");
        }

      });
    }
  }

  validarForm() {
    let camposValidar = ["email", "nombre_usuario"];
    let nombreCampos = { email: "Email", nombre_usuario: "Nombre Usuario" };
    let mensaje = "Estos campos son obligatorios: ";
    let error = false;

    this.validarCifDni();
    if (this.validCif) {
      if (this.user.municipio != null && ((this.user.municipio.provincia.id != null && this.user.municipio.provincia.id != "") && this.user.municipio.id == "")) {
        mensaje = "Si eliges una provincia tienes que elegir un municipio.";
        this.mostrarMensajeModal("Editar Perfil", mensaje);
        return false;
      } else {
        for (let i = 0; i < camposValidar.length; i++) {
          if (this.user[camposValidar[i]] == undefined || this.user[camposValidar[i]] == "" || this.user[camposValidar[i]] == null) {
            mensaje += nombreCampos[camposValidar[i]] + ",";
            error = true;
          }
        }
      }


    } else {
      mensaje = "Su DNI/CIF no es correcto.";
      this.mostrarMensajeModal("Editar Perfil", mensaje);
      return false;
    }

    if (!error) {
      return true;
    } else {
      mensaje = mensaje.substring(0, (mensaje.length - 1));
      this.mostrarMensajeModal("Editar Perfil", mensaje);
      return false;
    }
  }

  mostrarMensajeModal(titulo, mensaje) {
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(ModalMensajeComponent);
    this.refModalMensaje = this.entry.createComponent(factory);
    this.refModalMensaje.instance.mensaje = mensaje;
    this.refModalMensaje.instance.titulo = titulo;
    this.refModalMensaje.changeDetectorRef.detectChanges();
  }

  getRolUser(userId: number) {
    this.pService.getRolUser(userId).subscribe(result => {
      if (result.result == 'ok') {
        this.rolUsuario = result.rolUser;
      } else {
        this.mostrarMensajeModal("Rol", "Se ha producido un error al obtener el rol del usuario");
      }
    })
  }

  eliminarCuenta = () => {
    if (this.rolUsuario.includes(2) || this.rolUsuario.includes(3)) {
      this.mostrarMensajeModal("Eliminar Cuenta", "Para eliminar esta cuenta ponte en contacto con el administrador info@uati.com");
    } else {
      this.mostrarMensajeModalPersonalizado("Eliminar Cuenta",
        "Esta acción eliminará permanentemente tu cuenta y todos los datos relacionados. ¿Estás seguro de que deseas eliminarla?", "Eliminar", this.BorrarUser);
    }
  }

  BorrarUser = () => {
    this.pService.deleteUser(this.user.id).subscribe(result => {
      if (result.result == "ok") {
        localStorage.removeItem('user');
        this.router.navigateByUrl('login');
      } else {
        this.mostrarMensajeModal("Perfil", "Se ha producido un error al eliminar la cuenta");
      }
    });
  }

  mostrarMensajeModalPersonalizado(titulo, mensaje, textoBotonConfirmar, funcionConfirmar) {
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(ModalConfirmComponent);
    this.refModalMensaje = this.entry.createComponent(factory);
    this.refModalMensaje.instance.mensaje = mensaje;
    this.refModalMensaje.instance.titulo = titulo;
    this.refModalMensaje.instance.botones = [
      { texto: textoBotonConfirmar, accion: funcionConfirmar },
      //{ texto: textoBotonCancelar, accion: funcionCancelar }

      /*      { texto: textoBotonConfirmar, accion: () => console.log('Botón OK presionado') },
           { texto: textoBotonCancelar, accion: () => console.log('Botón Cancelar presionado') } */
    ];
    this.refModalMensaje.changeDetectorRef.detectChanges();
  }

  atras = () => {
    this.router.navigate(['perfil'], { queryParams: { user_id: this.user.id } });
  }

  onProvinciaChange(value) {

    if (value != undefined && value.value != "") {
      if (this.user.municipio == null) {
        this.user.municipio = { id: "", provincia: { id: value.value } };
      } else {
        this.user.municipio.provincia.id = value.value;
      }
    } else {
      if (this.user.municipio == null) {
        this.user.municipio = { id: "", provincia: { id: null } };
      } else {
        this.user.municipio.provincia.id = this.valueProvincia;
      }

    }

    if (this.municipios != undefined) {
      this.filteredMunicipios = this.municipios.filter(
        (municipio) => +municipio.id_provincia === +value.value
      );

      this.filteredMunicipios.unshift({ id: '', text: "" });

    }

  }

  get isNovedadesChecked(): boolean {
    return this.user.novedades === "1";
  }

  set isNovedadesChecked(value: boolean) {
    this.user.novedades = value ? "1" : "0";
  }

  onMunicipioChange(value) {
    this.user.municipio.id = value.value;
  }

  validarCifDni() {
    let valor = this.user.nif_cif;

    if (valor != null && valor != "" && valor != undefined) {
      if (this.validarDNI(valor) || this.isValidCif(valor)) {
        this.validCif = true;
      } else {
        this.validCif = false;
      }
    } else {
      this.validCif = true;
    }
  }

  validarDNI(dni) {
    var numero
    var letr
    var letra
    var expresion_regular_dni

    expresion_regular_dni = /^\d{8}[a-zA-Z]$/;

    if (expresion_regular_dni.test(dni) == true) {
      numero = dni.substr(0, dni.length - 1);
      letr = dni.substr(dni.length - 1, 1);
      numero = numero % 23;
      letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
      letra = letra.substring(numero, numero + 1);
      if (letra != letr.toUpperCase()) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  isValidCif(abc) {
    if (abc != null) { //No haga nada si lo que se le pase por parametro este vacio
      let par = 0;
      let non = 0;
      let letras = "ABCDEFGHKLMNPQS";
      let letra = abc.charAt(0);

      if (abc.length != 9) {
        //alert('El Cif debe tener 9 dígitos');
        return false;
      }

      if (letras.indexOf(letra.toUpperCase()) == -1) {
        //alert("El comienzo del Cif no es válido");
        return false;
      }

      for (let zz = 2; zz < 8; zz += 2) {
        par = par + parseInt(abc.charAt(zz));
      }

      for (let zz = 1; zz < 9; zz += 2) {
        let nn = 2 * parseInt(abc.charAt(zz));
        if (nn > 9) nn = 1 + (nn - 10);
        non = non + nn;
      }

      let parcial = par + non;
      let control = (10 - (parcial % 10));
      if (control == 10) control = 0;

      if (control != abc.charAt(8)) {

        return false;
      }
      return true;
    }
    return false;
  }

  onSexoChange(value) {
    this.user.tipoSexoId = value.value;
  }

  onTiposDietaChange(value) {
    this.user.tiposDieta = value.value;
  }

  onAlergiasChange(value) {
    this.user.alergias = value.value;
  }
}
